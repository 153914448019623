import { useNavigate } from "react-router-dom";

export default function PageBreadcrumbs(props) {
    const navigate = useNavigate();

    const { icon, pageName, breadcrumbs } = props;

    return (
        <section className="d-flex mb-2 mt-2">
            <div className="flex-grow-1">
                <section className="d-flex align-items-start">
                    <div className="mx-3 pagetitle ms-0">
                        <div className="d-flex flex-row  align-items-center">
                            <div className="heading-icon-style">
                                <span className="material-symbols-outlined">
                                    {icon}
                                </span>
                            </div>
                            <div>
                                <h1 className="mt-2">{pageName}</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        {breadcrumbs.map((breadcrumb, index) => (breadcrumb.route ?
                                            (
                                                <li key={index} className="breadcrumb-item">
                                                    <a onClick={() => navigate(breadcrumb.route)}>
                                                        {breadcrumb.label}
                                                    </a>
                                                </li>
                                            ) : (
                                                <li key={index} className="breadcrumb-item active">
                                                    {breadcrumb.label}
                                                </li>
                                            )
                                        ))}
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}