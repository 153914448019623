const parseRtdError = (error) => {
    let message = 'An unknown error occurred';

    if (error?.response?.data?.error) {
      message = error.response.data.error;
    }else if(error?.response?.data?.errors){
        message = Object.entries(error.response.data.errors)
            .map(([key, errors]) => errors.join('\n'))
            .join('\n');
    }else if(error?.response?.data?.title){
        message = error.response.data.title;
    }else if(error?.response?.data?.message){
        message = error.response.data.message;
    }

    return message;
};

export { parseRtdError };
