import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    sensors: [],
    createdSensor: {},
    sensorById: {},
    error: '',
    loading: false,
};

export const getAllSensor = createAsyncThunk(
    'sensor/getAllSensor',
    async () => {
        try {
            const url = `/api/v2/sensors`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const createSensor = createAsyncThunk(
    'sensor/createSensor',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/sensors`, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getSensorById = createAsyncThunk(
    'sensor/getSensorById',
    async (id) => {
        try {
            const url = `/api/v2/sensors/${id}`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const updateSensor = createAsyncThunk(
    'sensor/updateSensor',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/sensors/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const deleteSensor = createAsyncThunk(
    'sensor/deleteSensor',
    async (id) => {
        try {
            const url = `/api/v2/sensors/${id}`;
            const response = await axiosApiInstance.delete(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const sensorSlice = createSlice({
    name: 'sensor',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllSensor.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllSensor.fulfilled, (state, action) => {
                state.loading = false;
                state.sensors = action.payload.data;
            })
            .addCase(getAllSensor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createSensor.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSensor.fulfilled, (state, action) => {
                state.loading = false;
                state.createdSensor = action.payload.data;
                state.sensors.push(action.payload.data);
            })
            .addCase(createSensor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getSensorById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSensorById.fulfilled, (state, action) => {
                state.loading = false;
                state.sensorById = action.payload.data;
            })
            .addCase(getSensorById.rejected, (state, action) => {
                state.loading = false;
                state.sensorById = {};
                state.error = action.error.message;
            })

            .addCase(updateSensor.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSensor.fulfilled, (state, action) => {
                state.loading = false;

                const updatedSensor = action.payload.data;
                const index = state.sensors.findIndex((sensor) => sensor.id === updatedSensor.id);

                if (index !== -1) {
                    state.sensors[index] = updatedSensor;
                }
            })
            .addCase(updateSensor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(deleteSensor.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteSensor.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteSensor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default sensorSlice.reducer;
