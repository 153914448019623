import React from "react";
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useAsyncDebounce,
    useFilters,
} from "react-table";
import { message } from "../../@core/data/message";
import { Tooltip, Fade } from '@mui/material';
import "../../custom.css";

const Table = (params) => {
    const { COLUMNS, parseData, hideColumn, heading, getTooltipContent, tableWrapStyle, hidePagination } = params;
    const columns = React.useMemo(() => COLUMNS, [COLUMNS]);
    const data = React.useMemo(() => parseData, [parseData]);
    const initialState = { hiddenColumns: hideColumn };

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            //initialState: { pageIndex: 0 },
            initialState,
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        usePagination
    );

    // Render the UI for your table
    return (
        <div>
            <div className="d-flex bd-highlight">
                <div className="p-2 flex-grow-1">
                    <h5 className="card-title pr-1">{heading}</h5>
                </div>
                <div className="p-4 pe-0">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
            </div>

            <div className="table-responsive" style={{ ...tableWrapStyle }}>
                <table
                    {...getTableProps()}
                    className="table table-striped table-hover mb-2"
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps({
                                            className: column.collapse ? "collapse" : "",
                                        })}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <Tooltip
                                    key={i}
                                    TransitionComponent={Fade}
                                    placement="bottom-start"
                                    title={getTooltipContent ? getTooltipContent(parseData[i], hideColumn) : "-"}
                                    disableHoverListener={!(!!getTooltipContent)}
                                >
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        className: cell.column.collapse ? "collapse" : "",
                                                    })}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </Tooltip>
                            );
                        })}
                    </tbody>
                </table>

                {!data || (data.length <= 0 || data === null) && (
                    <div className="text-muted">{message.no_data}</div>
                )}

                {(hidePagination === undefined || !hidePagination) &&
                    <TablePagination {...{
                        pageIndex,
                        pageOptions,
                        gotoPage,
                        previousPage,
                        nextPage,
                        canPreviousPage,
                        canNextPage,
                        pageCount,
                        setPageSize,
                        pageSize
                    }} />
                }
            </div>
        </div>
    );
};

export default Table;

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 500);

    return (
        <section className="d-flex">
            <label className="form-label p-2 fw-bold m-0">Search</label>
            <input
                value={value || ""}
                className="form-control"
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Search all ${count} records...`}
                style={{
                    fontSize: "1.1rem",
                }}
            />
        </section>
    );
}

function DropdownFilter({
    column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
    // Calculate the options for filtering
    // using the rows and column id
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a select dropdown for filtering
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
        >
            <option value="">All</option>
            {options.map(option => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}


function TablePagination({
    pageIndex,
    pageOptions,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    setPageSize,
    pageSize,
}) {
    return (
        <div className="d-flex flex-wrap justify-content-end mb-1">
            <ul className="pagination justify-content-end mb-0">
                <li className="page-item pt-2">Rows:</li>
                <li className="page-item px-2">
                    <select
                        value={pageSize}
                        className="form-select"
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </li>
                <li className="page-item pt-2">
                    <div className="page-count mx-3" style={{ whiteSpace: 'nowrap' }}>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                    </div>
                </li>
            </ul>

            <ul className="pagination justify-content-end mb-0">
                <li className="page-item">
                    {" "}
                    <button
                        style={{ "width": "45px" }}
                        className={`page-link ${!canPreviousPage ? 'disabled' : ''}`}
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {"<<"}
                    </button>{" "}
                </li>
                <li className="page-item">
                    {" "}
                    <button
                        style={{ "width": "45px" }}
                        className={`page-link ${!canPreviousPage ? 'disabled' : ''}`}
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {"<"}
                    </button>{" "}
                </li>
                <li className="page-item">
                    <button
                        style={{ "width": "45px" }}
                        className={`page-link ${!canNextPage ? 'disabled' : ''}`}
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        {">"}
                    </button>{" "}
                </li>
                <li className="page-item">
                    <button
                        style={{"width": "45px"}}
                        className={`page-link ${!canNextPage ? 'disabled' : ''}`}
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {">>"}
                    </button>{" "}
                </li>
            </ul>
        </div>
    );
}
