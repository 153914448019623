import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    ipcs: [],
    createdIpc: {},
    ipcById: {},
    error: '',
    loading: false,
};

export const getAllIpc = createAsyncThunk(
    'ipc/getAllIpc',
    async () => {
        try {
            const url = `/api/v2/ipc`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const createIpc = createAsyncThunk(
    'ipc/createIpc',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/ipc`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getIpcById = createAsyncThunk(
    'ipc/getIpcById',
    async (id) => {
        try {
            const url = `/api/v2/ipc/${id}`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const updateIpc = createAsyncThunk(
    'ipc/updateIpc',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/ipc/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const ipcSlice = createSlice({
    name: 'ipc',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllIpc.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllIpc.fulfilled, (state, action) => {
                state.loading = false;
                state.ipcs = action.payload.data;
            })
            .addCase(getAllIpc.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createIpc.pending, (state) => {
                state.loading = true;
            })
            .addCase(createIpc.fulfilled, (state, action) => {
                state.loading = false;
                state.createdIpc = action.payload.data;
                state.ipcs.push(action.payload.data);
            })
            .addCase(createIpc.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getIpcById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getIpcById.fulfilled, (state, action) => {
                state.loading = false;
                state.ipcById = action.payload.data;
            })
            .addCase(getIpcById.rejected, (state, action) => {
                state.loading = false;
                state.ipcById = {};
                state.error = action.error.message;
            })

            .addCase(updateIpc.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateIpc.fulfilled, (state, action) => {
                state.loading = false;

                const updatedIpc = action.payload.data;
                const index = state.ipcs.findIndex((ipc) => ipc.id === updatedIpc.id);

                if (index !== -1) {
                    state.ipcs[index] = updatedIpc;
                }
            })
            .addCase(updateIpc.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default ipcSlice.reducer;
