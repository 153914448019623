// MUI
import {
  Box,
  Dialog, DialogTitle, DialogContent
} from '@mui/material';
// components
import ResetPasswordForm from "src/pages/users/components/ResetPasswordForm";

export default function ChangePasswordDialog({ open, onClose, user }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>Update Password</DialogTitle>
      <DialogContent>
        <Box>
          <ResetPasswordForm user={user} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
