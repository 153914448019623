import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext } from '@hello-pangea/dnd';
import { format } from 'date-fns';
// MUI
import {
    Typography, Box, Card, CardContent, CardHeader, Grid,
    ButtonGroup, Button, CircularProgress
} from '@mui/material';
// redux
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects, assignEquipment, removeEquipment } from "../../features/projectSlice";
import { getAvailableEquipment } from "../../features/equipmentSlice";
// components
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import { RtdCardTitle } from "src/components/RtdCard";
import { ProjectList } from './components/ProjectComponents';
import { iconSet } from "../../@core/data/icons";
import EquipmentDroppable from './components/projects/EquipmentDroppable';
import ProjectAccordion from './components/projects/ProjectAccordion';
import EquipmentDialog from './components/projects/EquipmentDialog';
import ProjectDialog from './components/projects/ProjectDialog';
import UnassignedDataKeys from './components/projects/UnassignedDataKeys';
// assets
import { RollerIcon2 } from 'src/assets';

// ----------------------------------------------------------------------

const DEFAULT_EQUIPMENT_MODAL = { open: false, equipment: null };
const DEFAULT_PROJECT_MODAL = { open: false, project: null };

const TabValues = Object.freeze({
    ACTIVE: 0,
    PAST: 1,
});

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "", label: "Projects" },
];

// ----------------------------------------------------------------------

export default function ProjectPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const { adminSelectedCompany } = useSelector((state) => state.company);
    const { projectsLoading, allProjects } = useSelector((state) => state.project);
    const { loading, availableEquipment } = useSelector((state) => state.equipment);
    const [projectsWithEquipment, setProjectsWithEquipment] = useState([]);
    const [orderedEquipment, setOrderedEquipment] = useState([]);
    const [curTab, setCurTab] = useState(0);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [equipmentDialog, setEquipmentDialog] = useState(DEFAULT_EQUIPMENT_MODAL);
    const [projectDialog, setProjectDialog] = useState(DEFAULT_PROJECT_MODAL);

    // intialize project list
    useEffect(() => {
        if (isSuperAdmin && adminSelectedCompany?.companyId) {
            const data = { companyId: adminSelectedCompany.companyId };
            dispatch(getAllProjects(data));
            dispatch(getAvailableEquipment(data));
        } else {
            dispatch(getAllProjects());
            dispatch(getAvailableEquipment());
        }
    }, [dispatch, adminSelectedCompany]);

    // initialize ordered equipment for Drag-n-drop
    useEffect(() => {
        setOrderedEquipment([...availableEquipment]);
    }, [availableEquipment]);

    // initialize project equipment
    useEffect(() => {
        setProjectsWithEquipment(allProjects.map((project) => {
            return {
                ...project,
                equipment: project.equipment || [],
            };
        }));
    }, [allProjects]);

    // initialize filtered projects
    useEffect(() => {
        const filtered = filterProjects(projectsWithEquipment, curTab);
        setFilteredProjects(filtered);
    }, [projectsWithEquipment, curTab]);

    const filterProjects = (projects, tab) => {
        const todayDate = format(new Date(), 'yyyy-MM-dd');

        return projects.filter((project) => {
            if (tab === TabValues.ACTIVE) {
                return project.endDate >= todayDate;
            } else {
                return project.endDate < todayDate;
            }
        });
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const srcDroppableId = source.droppableId;
        const dstDroppableId = destination.droppableId;

        if (srcDroppableId === dstDroppableId) {
            handleDropReorder(srcDroppableId, source.index, destination.index);
        } else {
            handleDropMove(srcDroppableId, dstDroppableId, source, destination);
        }
    };

    const handleDropReorder = (droppableId, srcIndex, dstIndex) => {
        if (droppableId === '-1') {
            const items = reorderListItem(orderedEquipment, srcIndex, dstIndex);
            setOrderedEquipment(items);
        } else {
            const projectIndex = projectsWithEquipment.findIndex(project => project.projectSK === +droppableId);
            const items = reorderListItem(projectsWithEquipment[projectIndex].equipment, srcIndex, dstIndex);
            const newState = [...projectsWithEquipment];
            newState[projectIndex].equipment = items;
            setProjectsWithEquipment(newState);
        }
    };

    const handleDropMove = (srcDroppableId, dstDroppableId, source, destination) => {
        const sourceProject = srcDroppableId === '-1' ?
            { equipment: orderedEquipment } : projectsWithEquipment.find(project => project.projectSK === +srcDroppableId);
        const destinationProject = dstDroppableId === '-1' ?
            { equipment: orderedEquipment } : projectsWithEquipment.find(project => project.projectSK === +dstDroppableId);

        const movedEquipment = sourceProject.equipment[source.index];

        // API call to update equipment assignment
        if (srcDroppableId !== '-1') {
            dispatch(removeEquipment({ projectId: +srcDroppableId, equipmentId: movedEquipment.equipmentSK }));
        }
        // API call to update the equipment assignment
        if (dstDroppableId !== '-1') {
            dispatch(assignEquipment({ projectId: +dstDroppableId, equipmentId: movedEquipment.equipmentSK }));
        }

        const moveResult = moveListItem(sourceProject.equipment, destinationProject.equipment, source, destination);

        if (srcDroppableId === '-1') {
            setOrderedEquipment(moveResult[srcDroppableId]);
            updateDropProjectEquipment(dstDroppableId, moveResult[dstDroppableId]);
        } else if (dstDroppableId === '-1') {
            setOrderedEquipment(moveResult[dstDroppableId]);
            updateDropProjectEquipment(srcDroppableId, moveResult[srcDroppableId]);
        } else {
            updateDropProjectEquipment(srcDroppableId, moveResult[srcDroppableId]);
            updateDropProjectEquipment(dstDroppableId, moveResult[dstDroppableId]);
        }
    };

    const updateDropProjectEquipment = (projectSK, equipment) => {
        const projectIndex = projectsWithEquipment.findIndex(project => project.projectSK === +projectSK);
        const newState = [...projectsWithEquipment];
        newState[projectIndex].equipment = equipment;
        setProjectsWithEquipment(newState);
    };

    const reorderListItem = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    // Moves an item from one list to another list and returns the updated lists
    const moveListItem = (source, destination, droppableSource, droppableDestination) => {
        const srcClone = Array.from(source);
        const dstClone = Array.from(destination);
        const [removed] = srcClone.splice(droppableSource.index, 1);

        dstClone.splice(droppableDestination.index, 0, removed);

        const result = {};

        result[droppableSource.droppableId] = srcClone;
        result[droppableDestination.droppableId] = dstClone;

        return result;
    };

    const onEquipmentClick = (equipment) => {
        setEquipmentDialog({
            open: true,
            equipment: equipment,
        });
    }

    const closeEquipmentDialog = () => {
        setEquipmentDialog({
            ...equipmentDialog,
            open: false,
        });
    }

    const onProjectClick = (project) => {
        setProjectDialog({
            open: true,
            project: project,
        });
    }

    const closeProjectDialog = () => {
        setProjectDialog({
            ...projectDialog,
            open: false,
        });
    }

    const onCreateProject = () => {
        navigate('/projects/form');
    }

    return (
        <Box className="d-flex flex-column px-md-4">
            <Box className="flex-grow-1">
                <PageBreadcrumbs pageName="Projects" breadcrumbs={breadcrumbs} icon={iconSet.location} />
            </Box>

            <Box>
                <UnassignedDataKeys />
            </Box>

            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={2} display="flex" flexDirection="column">
                        <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <CardHeader
                                title={
                                    <RtdCardTitle>
                                        <Box component="img" src={RollerIcon2} sx={{ height: 30, mr: 1 }} />
                                        Equipment
                                    </RtdCardTitle>
                                }
                                subheader="Not Assigned"
                            />

                            {!(projectsLoading || loading) && (
                                <CardContent sx={{ height: '100%' }}>
                                    <EquipmentDroppable equipment={orderedEquipment} onEquipmentClick={onEquipmentClick} />
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={10} display="flex" flexDirection="column">
                        <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <CardHeader
                                    title={<RtdCardTitle>Projects</RtdCardTitle>}
                                />
                                <Box>
                                    <Button sx={{ mr: 1 }} variant="contained" onClick={onCreateProject}>
                                        Add Project
                                    </Button>
                                </Box>
                            </Box>

                            {(projectsLoading || loading) && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
                                    <CircularProgress />
                                </Box>
                            )}

                            {!(projectsLoading || loading) && (
                                <CardContent>
                                    <ButtonGroup variant="text" aria-label="Filter Projects">
                                        <Button
                                            onClick={() => setCurTab(TabValues.ACTIVE)}
                                            variant={curTab === TabValues.ACTIVE ? 'contained' : 'outlined'}
                                        >
                                            Active Projects
                                        </Button>
                                        <Button
                                            onClick={() => setCurTab(TabValues.PAST)}
                                            variant={curTab === TabValues.PAST ? 'contained' : 'outlined'}

                                        >
                                            Past Projects
                                        </Button>
                                    </ButtonGroup>

                                    <ProjectList>
                                        {filteredProjects.length === 0 &&
                                            <Typography sx={{ color: 'grey.600', mt: 2 }}>
                                                No {curTab === TabValues.ACTIVE ? 'active' : 'past'} projects found
                                            </Typography>
                                        }

                                        {filteredProjects.map((project, index) => (
                                            <Box key={project.projectSK}>
                                                <ProjectAccordion
                                                    projectWithEquipment={project}
                                                    index={index}
                                                    onEquipmentClick={onEquipmentClick}
                                                    onProjectClick={onProjectClick}
                                                />
                                            </Box>
                                        ))}
                                    </ProjectList>
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </DragDropContext>

            <EquipmentDialog isOpen={equipmentDialog.open} onClose={closeEquipmentDialog} equipment={equipmentDialog.equipment} />
            <ProjectDialog isOpen={projectDialog.open} onClose={closeProjectDialog} project={projectDialog.project} />
        </Box>
    );
};

