import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
// redux
import { getAllEquipment } from "../../features/equipmentSlice";
// config
import { permission } from "../../@core/data/permission";
// MUI
import {
    Box, Card, CardContent, Button,
} from "@mui/material";
// components
import ActionMenu from "../../components/actionmenu";
import Table from "../../components/table/Table";
import { iconSet } from "../../@core/data/icons";
import { ToastSuccess, ToastError } from "../../components/Toast";
import { CircleLoading } from "../../components/loadingCircle";
import ConfirmDialog from "src/components/ConfirmDialog";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";

// ----------------------------------------------------------------------

//let adminOptions = ["Edit", "Delete"]; // removing delete option until we have soft deletes
let adminOptions = ["Edit"];

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "", label: "Equipment" },
];

// ----------------------------------------------------------------------

export default function EquipmentPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const { adminSelectedCompany } = useSelector((state) => state.company);
    const { roles } = useSelector((state) => state.login);
    const { loading, allEquipment } = useSelector((state) => state.equipment);
    const [deleteConfirm, setDeleteConfirm] = useState({ open: false, equipmentSK: null });

    const canEditEquipment = roles.userCompanyRolePermissionInfo.some(
        (rolscheck) =>
            rolscheck.permissionName === permission.equipment && rolscheck.isWrite
        ) || isSuperAdmin;

    let hideColumn = canEditEquipment ? ['equipmentSK'] : ['companyName', 'equipmentSK', 'action'];

    const COLUMNS = [
        {
            Header: "ID",
            accessor: "equipmentID",
            Cell: ({ row }) => (
                <div>
                    <Link
                        style={{ textAlign: "left" }}
                        to={`/equipment/form?id=${row.values.equipmentSK}`}
                    >
                        {row.values.equipmentID}
                    </Link>
                </div>
            ),
        },
        {
            Header: "Company",
            accessor: "companyName",
        },
        {
            Header: "Project",
            accessor: "projectName",
        },
        {
            Header: "Vin Number",
            accessor: "vinNumber",
            Cell: ({ row }) => (
                <div>
                    {row.values.vinNumber ? row.values.vinNumber : "-"}
                </div>
            ),
        },
        {
            Header: "Drum Size",
            accessor: "drumSize",
            Cell: ({ row }) => (
                <div>
                    {row.values.drumSize ? row.values.drumSize : "-"}
                </div>
            ),
        },
        {
            Header: "Status",
            accessor: "systemStatus",
            Cell: ({ row }) => (
                <div>
                    {row.values.systemStatus ? row.values.systemStatus : "-"}
                </div>
            ),
        },
        {
            Header: "Equipment SK",
            accessor: "equipmentSK",
            show: false,
        },
        {
            Header: "Action",
            accessor: 'action',
            Cell: ({ row }) => (
                <Box>
                    {canEditEquipment && (
                        <ActionMenu
                            key="action-menu"
                            options={adminOptions}
                            handleMenuClick={(option) =>
                                handleItemMenuClick(row.values.equipmentSK, option)
                            }
                        />
                    )}
                </Box>
            ),
        },
    ];

    const onCreateClick = () => {
        navigate("/equipment/form");
    };

    useEffect(() => {
        if (isSuperAdmin && adminSelectedCompany?.companyId) {
            const data = { companyId: adminSelectedCompany.companyId };
            dispatch(getAllEquipment(data));
        } else {
            dispatch(getAllEquipment());
        }
    }, [dispatch, adminSelectedCompany]);

    const handleItemMenuClick = (equipmentSK, option) => {
        if (option === "Edit") {
            navigate(`/equipment/form?id=${equipmentSK}`);
        } else if (option === "Delete") {
            onDeleteClick(equipmentSK);
        }
    };

    const deleteEquipment = async (equipmentSK) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Origin", "http://localhost:3000");
        const token = localStorage.getItem("token");

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_APIURL}/Equipment/DeleteEquipment?equipmentId=${equipmentSK}&userId=${loginRes?.userId}`,
            config
        );

        if (response.ok) {
            const data = await response.json();

            if (data.messageKey && data.messageKey === "success") {
                ToastSuccess("Equipment deleted!");
                dispatch(getAllEquipment());
            } else if (data.messageKey === "failure") {
                ToastError("An unknown error occurred");
            }
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    };

    const closeDeleteConfirm = (confirm) => {
        if (confirm) {
            deleteEquipment(deleteConfirm.equipmentSK);
        }

        setDeleteConfirm({ open: false, equipmentSK: null });
    }

    const onDeleteClick = (equipmentSK) => {
        setDeleteConfirm({ open: true, equipmentSK: equipmentSK });
    }

    return (
        <Box sx={{ p: { md: 2 } }}>
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Equipment" breadcrumbs={breadcrumbs} icon={iconSet.equipment} />
                </Box>

                <Box className="heading-right-btn">
                    {canEditEquipment && (
                        <Box>
                            <Button sx={{ mr: 1 }} variant="contained" onClick={onCreateClick}>
                                Add Equipment
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <Card>
                <CardContent>
                    {loading && (<CircleLoading />)}

                    {!loading && (
                        <Table
                            COLUMNS={COLUMNS}
                            parseData={allEquipment}
                            hideColumn={hideColumn}
                            heading={"Equipment"}
                        />
                    )}
                </CardContent>
            </Card>

            <ConfirmDialog
                open={deleteConfirm.open}
                title="Delete Equipment"
                message="Are you sure you want to delete this equipment? This cannot be undone."
                onClose={closeDeleteConfirm}
                maxWidth="xs"
            />
        </Box>
    );
};

