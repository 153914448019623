import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// forms
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// MUI
import {
  Grid,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// redux
import { getCompanies } from "src/features/companySlice";
import { getRoles } from "src/features/adminSlice";
import { createUser, updateUser, userCreateLoading, userUpdateLoading } from "src/features/userSlice";
// components
import { ToastSuccess, ToastError } from 'src/components/Toast';
import LoadingButton from 'src/components/LoadingButton';

// ----------------------------------------------------------------------

const defaultFormValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  phone: '',
  companyRoleSK: '',
  defaultCompanySK: '',
  newIsSuperAdmin: false,
};

// ----------------------------------------------------------------------

export default function UserForm({ user, onSaved }) {
  const dispatch = useDispatch();
  const loginRes = JSON.parse(localStorage.getItem("loginRes"));
  const isSuperAdmin = loginRes?.superAdmin;
  const { adminSelectedCompany } = useSelector((state) => state.company);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const { allCompanies } = useSelector((state) => state.company);
  const { roles } = useSelector((state) => state.companyRole);
  const { userCreateLoading, userUpdateLoading } = useSelector((state) => state.user);

  // useMemo to rebuild the form when the user prop changes
  const formSchema = useMemo(() => {
    const baseSchema = {
      email: Yup.string().required("Email is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      phone: Yup.string().required("Phone is required"),
      newIsSuperAdmin: Yup.boolean(),
      companyRoleSK: Yup.string().when('newIsSuperAdmin', {
        is: false,
        then: () => Yup.string().required("Role is required"),
        otherwise: () => Yup.string().nullable()
      }),
      defaultCompanySK: Yup.string().when('newIsSuperAdmin', {
        is: false,
        then: () => Yup.string().required("Company is required"),
        otherwise: () => Yup.string().nullable()
      })
    };
  
    if (user) {
      // Don't allow updating password for existing user
      return Yup.object().shape(baseSchema);
    } else {
      // Require password for new user
      return Yup.object().shape({
        ...baseSchema,
        password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters")
      });
    }
  }, [user]);

  const {
    watch,
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: user || defaultFormValues,
  });

  useEffect(() => {
    const newRoleOptions = roles.map((role) => ({
      value: role.companyRoleSK,
      label: role.roleName,
    }));

    setRoleOptions(newRoleOptions);
  }, [roles]);

  useEffect(() => {
    if (user && user.defaultCompanySK) {
      dispatch(getRoles({ companyId: user.defaultCompanySK }));
    } else if (isSuperAdmin && adminSelectedCompany?.companyId) {
      setValue('defaultCompanySK', adminSelectedCompany.companyId);
      dispatch(getRoles({ companyId: adminSelectedCompany.companyId }));
    } else {
      if (!isSuperAdmin) {
        setValue('defaultCompanySK', loginRes.companyId);
      }

      dispatch(getRoles());
    }
  }, [user, adminSelectedCompany]);

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(getCompanies()).then(() => {
        const dropdownValues = allCompanies.map((company) => ({
          value: company.companySK,
          label: company.companyName,
        }));

        setCompanyOptions(dropdownValues);
      });
    }
  }, [dispatch]);

  const onCompanyChange = (newValue) => {
    if (newValue) {
      const data = { companyId: newValue };
      dispatch(getRoles(data))
    }
  };

  const onSubmit = async (data) => {
    const postData = {
      ...data,
      isSuperAdmin: data.newIsSuperAdmin || false,
    };

    if (user?.userSK) {
      const updateData = { id: user.userSK, data: postData };
      dispatch(updateUser(updateData)).then((res) => {
        if (res.payload?.data?.userSK) {
          ToastSuccess('User saved');
          onSaved(true);
        } else {
          // Relying on the error message returned by the Redux action to be displayed
          // ToastError('An unexpected error occurred.');
        }
      });
    } else {
      dispatch(createUser(postData)).then((res) => {
        if (res.payload?.data?.userSK) {
          ToastSuccess('User saved');
          onSaved(true);
        } else {
          // Relying on the error message returned by the Redux action to be displayed
          // ToastError('An unexpected error occurred.');
        }
      });
    }
  };

  const defaultCompanySK = watch('defaultCompanySK');
  const newIsSuperAdmin = watch('newIsSuperAdmin');

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={2}>
        {isSuperAdmin && !adminSelectedCompany?.companyId && !newIsSuperAdmin && (
          <Grid item sm={12} md={6}>
            <Controller
              name="defaultCompanySK"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  noOptionsText="Select Value"
                  options={companyOptions}
                  getOptionLabel={option => option.label}
                  value={companyOptions.find(item => item.value === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.value : '');
                    onCompanyChange(newValue ? newValue.value : '');
                  }}
                  renderInput={(params) =>
                    <TextField label="Company" {...params} />
                  }
                >
                </Autocomplete>
              )}
            />
            {errors.defaultCompanySK && (
              <p className="text-error">Company is required</p>
            )}
          </Grid>
        )}

        {!newIsSuperAdmin && (
          <Grid item sm={12} md={6}>
            <Controller
              name="companyRoleSK"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  noOptionsText="Select Value"
                  disabled={!defaultCompanySK}
                  options={roleOptions}
                  getOptionLabel={option => option.label}
                  value={roleOptions.find(item => item.value === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.value : '');
                  }}
                  renderInput={(params) =>
                    <TextField label="Role" {...params} />
                  }
                >
                </Autocomplete>
              )}
            />
            {errors.companyRoleSK && (
              <p className="text-error">Role is required</p>
            )}
          </Grid>
        )}

        {!newIsSuperAdmin && !(isSuperAdmin && !adminSelectedCompany?.companyId && !newIsSuperAdmin) && (
          <Grid item sm={12} md={6} />
        )}

        <Grid item sm={12} md={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="First Name" />
            )}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Last Name" />
            )}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField type="email" {...field} fullWidth error={!!error} helperText={error?.message} label="Email ID" />
            )}
          />
        </Grid>

        <Grid item sm={12} md={6}>
          <Controller
            name="phone"
            control={control}
            rules={{
              pattern: {
                value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                message: "Invalid phone number format"
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Phone" />
            )}
          />
        </Grid>
        {!user && (
          <Grid item sm={12} md={6}>
            <Controller
              name='password'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}

        {isSuperAdmin && !user?.userSK && (
          <Grid item sm={12}>
            <Controller
              name="newIsSuperAdmin"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value} 
                      onChange={(e) => onChange(e.target.checked)} 
                    />
                  }
                  label="Super Admin"
                />
              )}
            />
          </Grid>
        )}

        <Grid item sm={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={userCreateLoading || userUpdateLoading}
              sx={{ width: '110px' }}
            >
              Save
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

