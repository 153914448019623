import React from 'react';
import { Box, Typography, Paper, IconButton, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Login } from '@mui/icons-material';

const SensorDisplay = ({ sensor, onEdit, onDelete }) => {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;

    return (
        <Box>
            <Paper elevation={1} sx={{ p: 2, minWidth: 300, maxWidth: 300 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" align="center">{sensor.type} Sensor</Typography>

                    {isSuperAdmin && (
                        <Box>
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={() => onEdit(sensor)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton ><IconButton
                                color="error"
                                size="small"
                                onClick={() => onDelete(sensor)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )}
                </Box>

                <Divider sx={{ my: 1 }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Type:</Typography>
                    <Typography variant="body2">{sensor.type}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Make:</Typography>
                    <Typography variant="body2">{sensor.make}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Model:</Typography>
                    <Typography variant="body2">{sensor.model}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">Serial Number:</Typography>
                    <Typography variant="body2">{sensor.serialNumber}</Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default SensorDisplay;