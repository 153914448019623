import { useState, useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getRoleById, updateRolePermissions } from "src/features/adminSlice";
// MUI
import {
    Box,
} from "@mui/material";
// components
import Table from "src/components/table/Table";
import { ToastSuccess, ToastError } from "src/components/Toast";
import LoadingButton from "src/components/LoadingButton";
// types
import { PermissionTypes } from "src/@core/data/permission";

// ----------------------------------------------------------------------

const RolePermissionsForm = ({ roleId }) => {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [rolePermissionsTableData, setRolePermissionsTableData] = useState([]);
    const { roles } = useSelector((state) => state.login);

    const canEditRolePermissions = roles.userCompanyRolePermissionInfo.some(
        (rolscheck) =>
            rolscheck.permissionName === PermissionTypes.Roles && rolscheck.isWrite
    ) || isSuperAdmin;

    const hideColumn = ['companyRolePermissionSK'];

    const COLUMNS = [
        { Header: 'Permission Name', accessor: 'permissionName' },
        {
            Header: 'Is Read',
            accessor: 'isRead',
            Cell: ({ row, value }) => (
                <input
                    type="checkbox"
                    name="isRead"
                    checked={value}
                    disabled={!canEditRolePermissions}
                    onChange={(event) => handleCheckboxChange(event, row.index)}
                />
            ),
        },
        {
            Header: 'Is Write',
            accessor: 'isWrite',
            Cell: ({ row, value }) => (
                <input
                    type="checkbox"
                    name="isWrite"
                    checked={value}
                    disabled={!canEditRolePermissions}
                    onChange={(event) => handleCheckboxChange(event, row.index)}
                />
            ),
        },
        {
            Header: 'Is Delete',
            accessor: 'isDelete',
            Cell: ({ row, value }) => (
                <input
                    type="checkbox"
                    name="isDelete"
                    checked={value}
                    disabled={!canEditRolePermissions}
                    onChange={(event) => handleCheckboxChange(event, row.index)}
                />
            ),
        },
    ];

    useEffect(() => {
        if(roleId) {
            dispatch(getRoleById(roleId)).then((res) => {
                const modifiedRolePermissions = res.payload.data.companyRolePermissions.map(item => ({ ...item, dirtyBit: false }));
                setRolePermissionsTableData(modifiedRolePermissions);
            })
        }
    }, [roleId]);

    const handleCheckboxChange = (event, rowIndex) => {
        const { name, checked } = event.target;
        setRolePermissionsTableData((prevState) =>
            prevState.map((row, index) =>
                index === rowIndex ? { ...row, [name]: checked, dirtyBit: true } : row
            )
        );
    };

    const onSubmit = async () => {
        const dirtyRows = rolePermissionsTableData.filter((row) => row.dirtyBit);

        const tableData = dirtyRows.map((data) => {
            if (data.companyRolePermissionSK === 0) {
                return {
                    companyRoleSK: roleId,
                    permissionSK: data.permissionSK,
                    isDelete: data.isDelete,
                    isRead: data.isRead,
                    isWrite: data.isWrite
                };
            } else {
                return {
                    companyRolePermissionSK: data.companyRolePermissionSK,
                    companyRoleSK: roleId,
                    permissionSK: data.permissionSK,
                    isDelete: data.isDelete,
                    isRead: data.isRead,
                    isWrite: data.isWrite
                }
            }
        });

        if (tableData.length === 0) {
            ToastError('No changes to save!');
            return;
        }

        const updateData = {
            id: roleId,
            data: {
                permissions: tableData
            },
        };

        setSubmitting(true);

        dispatch(updateRolePermissions(updateData)).then(() => {
            setSubmitting(false);
            ToastSuccess('Permissions saved');
        });
    };

    return (
        <Box>
            {rolePermissionsTableData &&
                <Box sx={{ m: 2 }}>
                    {<Table COLUMNS={COLUMNS} parseData={rolePermissionsTableData} hideColumn={hideColumn} heading={"Role Permissions"} hidePagination={true} />}
                </Box>
            }

            <Box sx={{ mt: 2, marginLeft: '14px' }}>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    sx={{ width: '190px' }}
                    onClick={onSubmit}
                    loading={submitting}
                >
                    Save Permissions
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default RolePermissionsForm;