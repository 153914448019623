import { useState, useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getPermissions, deletePermission } from "src/features/adminSlice";
// assets
import { iconSet } from "src/@core/data/icons";
// components
import Table from "src/components/table/Table";
import DialogBox from "src/components/DialogBox";
import PermissionForm from "./PermissionForm";
import ActionMenu from "src/components/actionmenu";
import { PermissionTypes } from "src/@core/data/permission";
import { CircleLoading } from "src/components/loadingCircle";
import { ToastSuccess } from 'src/components/Toast';
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import ConfirmDialog from "src/components/ConfirmDialog";

// ----------------------------------------------------------------------

const actionOptions = ["Edit", "Delete"];

const breadcrumbs = [
  { route: "/home", label: "Home" },
  { route: "/users", label: "Users" },
  { route: "", label: "Permissions" },
];

// ----------------------------------------------------------------------

const PermissionPage = () => {
  const loginRes = JSON.parse(localStorage.getItem("loginRes"));
  const isSuperAdmin = loginRes?.superAdmin;
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setisEditOpen] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState({ open: false, permission: null });
  const { roles } = useSelector((state) => state.login);
  const { loading, permissions } = useSelector((state) => state.companyRole);
  const { adminSelectedCompany } = useSelector((state) => state.company);

  const canEditPermissions = roles.userCompanyRolePermissionInfo.some(
    (rolscheck) =>
      rolscheck.permissionName === PermissionTypes.Permission && rolscheck.isWrite
  ) || isSuperAdmin;

  let hideColumn = isSuperAdmin ? ['companySK', 'permissionSK'] : ['companySK', 'permissionSK', 'companyName'];
  // Remove the Action column if the user cannot edit permissions
  if (!canEditPermissions) {
    hideColumn.push('Action');
  }

  const COLUMNS = [
    {
      Header: "Company Permission Id",
      accessor: "permissionSK",
    },
    {
      Header: "Company",
      accessor: "companyName",
      Cell: ({ row }) => <div>{row.values.companyName ? row.values.companyName : "-"}</div>,
    },
    {
      Header: "Permission",
      accessor: "name",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div>
              <ActionMenu
                key="action-menu"
                options={actionOptions}
                handleMenuClick={(option) => handleItemMenuClick(row, option)}
              />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (isSuperAdmin && adminSelectedCompany?.companyId) {
      const data = { companyId: adminSelectedCompany.companyId };
      dispatch(getPermissions(data));
    } else {
      dispatch(getPermissions());
    }
  }, [dispatch, adminSelectedCompany]);

  const handleItemMenuClick = (row, option) => {
    if (option === "Edit") {
      setisEditOpen(true);
      setRowData(row.original);
    } else if (option === "Delete") {
      setDeleteConfirm({ open: true, user: row.original });
    }
  };

  const onDeleteConfirmClose = (confirm) => {
    setDeleteConfirm({ open: false, permission: null });

    if (confirm) {
      submitDeletePermission(deleteConfirm.user.permissionSK)
    }
  };

  const submitDeletePermission = async (permissionSK) => {
    dispatch(deletePermission(permissionSK)).then((res) => {
      ToastSuccess('Permission deleted');
      dispatch(getPermissions());
    });
  };

  return (
    <div>
      <div className="d-flex flex-column px-md-4 form-layout customer-form">
        <section className="d-flex mb-2 mt-2">
          <div className="flex-grow-1">
            <PageBreadcrumbs pageName="Permissions" breadcrumbs={breadcrumbs} icon={iconSet.permission} />
          </div>
          <div className="heading-right-btn">
            {isSuperAdmin ? (
              <button
                className="btn btn-primary ms-3"
                onClick={() => setIsOpen(true)}
              >
                Create Permission
              </button>
            ) : (
              roles.userCompanyRolePermissionInfo.map((rolscheck) => {
                return (
                  rolscheck.permissionName === PermissionTypes.permission &&
                  rolscheck.isWrite && (
                    <button
                      className="btn btn-primary ms-3"
                      onClick={() => setIsOpen(true)}
                    >
                      Create Permission
                    </button>
                  )
                );
              })
            )}
          </div>
        </section>
        <div className="card">
          <div className="card-body">
            {loading ? (
              <div>
                <CircleLoading />
              </div>
            ) : (
              <Table
                COLUMNS={COLUMNS}
                parseData={permissions}
                hideColumn={hideColumn}
                heading={"Permissions"}
              />
            )}
          </div>
        </div>
      </div>
      <DialogBox
        title="Create Permission"
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        hideButtons={true}
      >
        <PermissionForm
          permission={null}
          handleClose={() => setIsOpen(false)}
        />
      </DialogBox>

      <DialogBox
        title="Edit Permission "
        isOpen={isEditOpen}
        handleClose={() => setisEditOpen(false)}
        hideButtons={true}
      >
        <PermissionForm
          permission={rowData}
          handleClose={() => setisEditOpen(false)}
        />
      </DialogBox>

      <ConfirmDialog
        open={deleteConfirm.open}
        title="Delete Permission"
        message="Are you sure you want to delete this permission?"
        onClose={onDeleteConfirmClose}
        maxWidth="xs"
      />
    </div>
  );
};

export default PermissionPage;
