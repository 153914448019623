import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const ToastSuccess = (msg) => {
  toast.success(msg, { position: "top-right", autoClose: 3000 });
};

export const ToastWarning = (msg, time) => {
  toast.warning(msg, { position: "top-right", autoClose: time ? time : 5000 });
};

export const ToastError = (msg, time) => {
  toast.error(msg, { position: "top-right", autoClose: time ? time : 5000 });
};
