

export function logout() {
    navigate("/");
  }


  export const userName = () => {
    return localStorage.getItem("userName");
  } 

 