import { Droppable, Draggable } from '@hello-pangea/dnd';
// MUI
import { Box } from '@mui/material';
import styled from '@mui/material/styles/styled';
// components
import { SystemStatusBadge } from '../ProjectComponents';
// assets
import { RollerIcon2 } from 'src/assets';

// ----------------------------------------------------------------------

export const CustomDraggablesContainer = styled(Box)(({ theme }) => ({
    background: "#f1f2f4",
    padding: dndGridSize,
    height: '100%',
    width: '100%',
    minHeight: '50px',

    borderRadius: '3px',
    overflowX: 'hidden',
    overflowY: 'hidden',

    '&::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#e3e5ea',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#c4c9d2',
        borderRadius: '10px',
        border: '3px solid #e3e5ea',
    }
}));

export const CustomDraggableItem = styled(Box)(({ theme }) => ({
    userSelect: "none",
    padding: dndGridSize * 2,
    margin: `0 0 ${dndGridSize}px 0`,
    borderRadius: '3px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    boxShadow: '0px 1px 1px #091e4240, 0px 0px 1px #091e424f',
    color: '#212529',
    margin: `0 0 ${dndGridSize}px 0`,

    minHeight: '40px',
    height: '40px',
    width: '100%',
    justifyContent: 'start',
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',

    // change background colour if dragging
    background: "#ffffff",
    border: '2px solid #ffffff',

    '&:hover': {
        cursor: 'pointer',
        border: '2px solid #8bd0db',
    }
}));

// ----------------------------------------------------------------------

const EquipmentDroppable = ({ equipment, onEquipmentClick }) => {
    return (
        <Droppable droppableId={`${-1}`}>
            {(provided, snapshot) => (
                <CustomDraggablesContainer
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {equipment.map((item, index) => (
                        <Draggable key={`${item.equipmentSK}`} draggableId={`${item.equipmentSK}`} index={index}>
                            {(provided, snapshot) => (
                                <CustomDraggableItem
                                    onClick={() => onEquipmentClick(item)}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <Box component="img" src={RollerIcon2} sx={{ height: 30, mr: 1 }} />
                                    {item.equipmentID}
                                    <SystemStatusBadge value={item.systemStatus}></SystemStatusBadge>
                                </CustomDraggableItem>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </CustomDraggablesContainer>
            )}
        </Droppable>
    );
};

export default EquipmentDroppable;

const dndGridSize = 4;
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#E4E6EA" : "#f1f2f4",
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "#f1f1f1f0" : "#ffffff",
    borderColor: isDragging ? "#f1f1f1f0" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});
