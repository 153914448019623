import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

// ----------------------------------------------------------------------
export default function ViewUserDialog({ open, onClose, user }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <UserProfileCard user={user} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const UserProfileCard = ({ user }) => {
  if (!user) return null;

  console.log('user', user);

  return (
    <>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }} aria-label="user">
            {user.firstName[0] + user.lastName[0]}
          </Avatar>
        }
        title={`${user.firstName} ${user.lastName}`}
        subheader={user.email}
      />
      <CardContent sx={{ pt: 0}}>
        <Typography variant="body2" color="text.secondary">
          Phone: {user.phone}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Company: {user.companyName ? user.companyName : '-'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Role: {user.roleName ? user.roleName : '-'}
        </Typography>
      </CardContent>
    </>
  );
};

