import * as React from 'react';
import { Link } from "react-router-dom";
// MUI
import {
    Box, Typography, Dialog, DialogTitle, DialogContent
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
// assets
import { RollerIcon2 } from 'src/assets';

// ----------------------------------------------------------------------

export default function EquipmentDialog({ isOpen, onClose, equipment }) {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box component="img" src={RollerIcon2} sx={{ height: 30, mr: 1 }} />
                    Equipment
                </Box>
                <Link
                    to={`/equipment/form?id=${equipment?.equipmentSK}`}
                    variant="body2"
                >
                    <EditIcon />
                </Link>
            </DialogTitle>
            <DialogContent>
                <Typography component="div" color="text.secondary">
                    <table>
                        <tbody>
                            <tr>
                                <td style={headerStyle}>ID:</td>
                                <td style={colStyle}>{equipment?.equipmentID}</td>
                            </tr>
                            <tr>
                                <td style={headerStyle}>Manufacturer:</td>
                                <td style={colStyle}>{equipment?.manufacturer ? equipment.manufacturer : '-'}</td>
                            </tr>
                            <tr>
                                <td style={headerStyle}>Model:</td>
                                <td style={colStyle}>{equipment?.model ? equipment.model : '-'}</td>
                            </tr>
                            <tr>
                                <td style={headerStyle}>Drum Size:</td>
                                <td style={colStyle}>{equipment?.drumSize ? equipment.drumSize : '-'}</td>
                            </tr>
                            <tr>
                                <td style={headerStyle}>Description:</td>
                                <td style={colStyle}>{equipment?.description ? equipment.description : '-'}</td>
                            </tr>
                            <tr>
                                <td style={headerStyle}>Status:</td>
                                <td style={colStyle}>{equipment?.systemStatus ? equipment.systemStatus : '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

const headerStyle = {
    width: '1px',
    borderWidth: 0,
    padding: '0 10px 5px 0',
    fontSize: '16px',
}
const colStyle = {
    borderWidth: 0,
    padding: '0 10px 5px 0',
    fontSize: '16px',
}