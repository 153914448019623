import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import companyReducer from './companySlice';
import projectReducer from './projectSlice';
import equipmentReducer from './equipmentSlice';
import systemInfoReducer from './systemInfoSlice';
import ipcReducer from './ipcSlice';
import sensorReducer from './sensorSlice';
import loginReducer from './loginSlice';
import companyRoleReducer from './adminSlice';
import customerReducer from './customerSlice';
import iotDeviceReducer from './iotdeviceSlice';
import tabletDeviceReducer from './tabletdeviceSlice';
import userReducer from './userSlice';
import plannerReducer from './plannerSlice';
import locationReducer from './locationSlice';
import commonReducer from './commonSlice';
import makeReducer from './makeSlice';
import drumReducer from './drumSizeSlice';
import statusReducer from './statusSlice';
import categoryReducer from './categorySlice';
import modelReducer from './modelSlice';
import deviceTypeReducer from './deviceTypeSlice';
import densityMapReducer from './densityMapSlice';
import dataKeyReducer from './dataKeySlice';

const persistConfig = {
    key: 'root', // key prefix for your persisted state
    storage, // the storage to use (e.g., localStorage)
    // Optionally, you can blacklist or whitelist specific reducers:
    // blacklist: ['company'], // ignore 'someReducer'
    //whitelist: ['login', 'densityMap'], // only persist 'anotherReducer'
    whitelist: ['login'], // only persist 'anotherReducer'
};

const rootReducer = combineReducers({
    company: companyReducer,
    project: projectReducer,
    equipment: equipmentReducer,
    systemInfo: systemInfoReducer,
    ipc: ipcReducer,
    sensor: sensorReducer,
    login: loginReducer,
    companyRole: companyRoleReducer,
    customer: customerReducer,
    iotDevice: iotDeviceReducer,
    tabletDevice: tabletDeviceReducer,
    user: userReducer,
    planner: plannerReducer,
    location:locationReducer,
    common: commonReducer,
    make: makeReducer,
    drumSize: drumReducer,
    status: statusReducer,
    category: categoryReducer,
    model: modelReducer,
    deviceType: deviceTypeReducer,
    densityMap: densityMapReducer,
    dataKey: dataKeyReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    // reducer: {
    //   company: companyReducer,
    //   project: projectReducer,
    //   equipment: equipmentReducer,
    //   login: loginReducer,
    //   companyRole: companyRoleReducer
    // }
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export const persistor = persistStore(store);
export default store;



