import React from "react";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// redux
import { useDispatch } from "react-redux";
import { createCustomer, updateCustomer } from "src/features/customerSlice";
import { getCustomerByIdV2 } from "src/features/customerSlice";
// MUI
import {
    Grid, TextField, Autocomplete, Box, Button
} from "@mui/material";
// components
import { ToastSuccess, ToastError } from "src/components/Toast";
import LoadingButton from 'src/components/LoadingButton';
import { usStates } from "src/@core/data/us-states"
import { usOnlyCountryList } from "src/@core/data/countries"

// ----------------------------------------------------------------------

const defaultFormValues = {
    companySK: "",
    firstName: "",
    customerID: "",
    email: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    phone: "",
    contact: "",
};

// ----------------------------------------------------------------------

/**
 * Provider customerId < 1 is treated as a placeholder/dummy record
 *   that is used to track the new customer object before submitting to the API.
 */
export default function CustomerForm({ companyId, customerId, onDelete, onSaved }) {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryValue, setCountryValue] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [stateValue, setStateValue] = useState("");   

    const formSchema = Yup.object().shape({
        email: Yup.string().required("Email is required").email("Invalid email address"),
    });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultFormValues
    });

    // effect runs when user state is updated
    const bindData = () => {
        if (customerId && customerId > 0) {
            dispatch(getCustomerByIdV2(customerId)).then((res) => {
                reset(res.payload);
                setSelectedCountry(res.payload.country);
                setSelectedState(res.payload.state);
            });
        } else {
            reset();
        }
    };

    useEffect(bindData, [dispatch]);

    const handleChangeState = (e, newValue) => {
        newValue === null ? setSelectedState('') : setSelectedState(newValue.key);
    };

    const handleChangeCountryValue = (e, newValue) => {
        setCountryValue(newValue);
    }

    const handleChangeCountry = (e, newValue) => {
        (newValue === null) ? setSelectedCountry('') : setSelectedCountry(newValue.code);
    };

    const onSubmit = (data) => {
        setSubmitting(true);

        if(customerId && customerId > 0){
            const postData = {
                ...data,
                customerSK: customerId || 0,
                companySK: companyId,
                state: selectedState,
                country: selectedCountry,
            };
            
            dispatch(updateCustomer(postData)).then((res) => {
                setSubmitting(false);
                
                if (res.payload?.data?.customerSK) {
                    onSaved(res.payload.data);
                    ToastSuccess("Customer saved!");
                } else {
                    ToastError("An unknown error occurred");
                }   
            });
        }else{
            const postData = {
                ...data,
                companySK: companyId,
                state: selectedState,
                country: selectedCountry,
            };

            dispatch(createCustomer(postData)).then((res) => {
                setSubmitting(false);
    
                if (res.payload?.data?.customerSK) {
                    onSaved(res.payload.data, customerId);
                    ToastSuccess("Customer saved!");
                } else {
                    ToastError("An unknown error occurred");
                }
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="firstName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Customer Name" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="customerID"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Customer Id" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField type="email" {...field} fullWidth error={!!error} helperText={error?.message} label="Email" required />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} />

                <Grid item xs={12} sm={6}>
                    <Controller
                        name="address1"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Address" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="address2"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Address 2" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="country"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState }) => (
                            <Autocomplete
                                noOptionsText="Select Value"
                                options={usOnlyCountryList}
                                getOptionLabel={option => option.name}
                                value={usOnlyCountryList.find((item) => item.code === selectedCountry) || null}
                                inputValue={countryValue}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        variant="outlined"
                                        label="Country"
                                        {...register("country", {
                                            validate: (value, formValues) => (
                                                countryValue != '' && !!usOnlyCountryList.find((item) => item.code === countryValue)
                                            )
                                        })} 
                                    />
                                }   
                                onChange={(event, value) => {
                                    handleChangeCountry(event, value);
                                }}
                                onInputChange={(event, newInputValue) => {

                                    handleChangeCountryValue(event, newInputValue);
                                }}
                            >
                            </Autocomplete>
                        )}
                    />
                    {errors.country && (
                        <p className="text-error">Please enter Country!</p>
                    )}
                </Grid>
                <Grid item sm={12} md={6}>
                    <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange } }) => (
                            <Autocomplete
                                noOptionsText={'Select Value'}
                                id="state"
                                options={usStates}
                                getOptionLabel={option => option.label}
                                value={usStates.find((item) => item.key === selectedState) || null}
                                inputValue={stateValue}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        variant="outlined"
                                        label="State"
                                        {...register("state", {
                                            validate: (value, formValues) => {
                                                console.log(value, stateValue);
                                                return stateValue != '' && !!usStates.find((item) => item.key === stateValue);
                                            }
                                        })}
                                    />}
                                onChange={(event, value) => {
                                    handleChangeState(event, value);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setStateValue(newInputValue)
                                }}
                            />
                        )}
                    />
                    {errors.state && (
                        <div className="text-error">Please enter State!</div>
                    )}
                </Grid>
                <Grid item sm={12} md={6}>
                    <Controller
                        name="city"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="City" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="zip"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Zip" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            pattern: {
                                value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                message: "Invalid phone number format"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Phone" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="contact"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Contact" />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => onDelete(customerId)}
                        >
                            Delete
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={submitting}
                            sx={{ width: '110px' }}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};