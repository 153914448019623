import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "src/features/projectSlice";
// MUI
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
// components
import Table from "src/components/table/Table";
import { CircleLoading } from "src/components/loadingCircle";
// util
import { formatDate } from "src/@core/utils/dateTimeFormatter";

// ----------------------------------------------------------------------

const TooltipLabel = styled(Box)(() => ({
    width: '60px'
}));

// ----------------------------------------------------------------------
export default function ProjectsTable() {
    const dispatch = useDispatch();
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const { projectsLoading, allProjects } = useSelector((state) => state.project);
    const hideColumn = ['projectSK'];
    const { adminSelectedCompany } = useSelector((state) => state.company);

    useEffect(() => {
        if(isSuperAdmin && adminSelectedCompany?.companyId) {
            const data = { companyId: adminSelectedCompany.companyId };
            dispatch(getAllProjects(data));
        } else {
            dispatch(getAllProjects());
        }
    }, [adminSelectedCompany]);

    const COLUMNS = [
        {
            Header: "Project",
            accessor: "projectName",
            Cell: ({ row }) => (
                <div>
                    <Link
                        style={{ textAlign: "left" }}
                        to={`/projects/form?id=${row.values.projectSK}`}
                    >
                        {row.values.projectName}
                    </Link>
                </div>
            ),
        },
        {
            Header: "Start",
            accessor: "startDate",
            Cell: ({ row }) => (
                <div>
                    {formatDate(row.values.startDate, "yyyy-MM-dd'T'HH:mm:ss", 'MM/dd/yyyy')}
                </div>
            ),
        },
        {
            Header: "End",
            accessor: "endDate",
            Cell: ({ row }) => (
                <div>
                    {formatDate(row.values.endDate, "yyyy-MM-dd'T'HH:mm:ss", 'MM/dd/yyyy')}
                </div>
            ),
        },
        {
            Header: "Project SK",
            accessor: "projectSK",
            show: false,
        },
    ];

    const getTooltipContent = (data, tooltopHideColumn) => {
        return (
            <Box>
                <Box className="d-flex flex-row">
                    <TooltipLabel>Name:</TooltipLabel> {data.projectName}
                </Box>
                {/* {tooltopHideColumn.includes("customerName") ?
                    null :
                    <Box className="d-flex flex-row">
                        <TooltipLabel>Customer:</TooltipLabel> {data.customerName}
                    </Box>
                } */}
                <Box className="d-flex flex-row">
                    <TooltipLabel>Start:</TooltipLabel> {data.startDate ? formatDate(data.startDate, "yyyy-MM-dd'T'HH:mm:ss", 'MM/dd/yyyy') : '-'}
                </Box>
                <Box className="d-flex flex-row">
                    <TooltipLabel>End:</TooltipLabel> {data.endDate ? formatDate(data.endDate, "yyyy-MM-dd'T'HH:mm:ss", 'MM/dd/yyyy') : '-'}
                </Box>
                {/* <Box className="d-flex flex-row">
                    <TooltipLabel>Location:</TooltipLabel> {data.location}
                </Box> */}
            </Box >
        );
    };

    return (
        <>
            {projectsLoading && (
                <div>
                    <CircleLoading />
                </div>
            )}
            {(!projectsLoading && (
                <div className="table-text-nowrap" style={{ overflowX: 'hidden' }}>
                    <Table
                        COLUMNS={COLUMNS}
                        parseData={allProjects}
                        hideColumn={hideColumn}
                        heading={"Projects"}
                        getTooltipContent={getTooltipContent}
                        tableWrapStyle={{ overflowX: 'hidden' }}
                    />
                </div>
            ))}
        </>
    );
}