import { useNavigate } from "react-router-dom";
// MUI
import { Box, Card, Typography, Button, IconButton } from '@mui/material';
import HexagonIcon from '@mui/icons-material/Hexagon';
import EditIcon from '@mui/icons-material/Edit';

export default function DataKeyCard({ dataKey, onEdit, disableMapButton }){
    const navigate = useNavigate();

    const onClick = (keyValue, projectSK) => {
        navigate(`/projects/${projectSK}/density-map?dataKey=${keyValue}`);
    }

    return (
        <Card sx={{ p: 1, m: 1, width: '200px', textAlign: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                        {dataKey.equipmentID ? dataKey.equipmentID : '-'}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {dataKey.timestamp ? dataKey.local_timestamp : '-'}
                    </Typography>
                </Box>
                <IconButton size="small" onClick={() => onEdit(dataKey)}>
                    <EditIcon />
                </IconButton>
            </Box>
            <Box>
                <Typography variant="caption" sx={{ color: 'text.rtd' }}>
                    <HexagonIcon sx={{ fontSize: 75 }} />
                </Typography>
            </Box>
            <Box textAlign="right">
                <Button 
                    size="small" 
                    variant="text" 
                    onClick={() => onClick(dataKey.keyValue, dataKey.projectSK)}
                    disabled={disableMapButton}
                >
                    View Map
                </Button>
            </Box>
        </Card>
    );
};