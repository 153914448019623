import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    statusListLoading: false,
    statusRowLoading: false,
    allStatus: [],
    statusById: [],
    formSuccess: [],
    error: ''
};

export const getAllStatus = createAsyncThunk(
    'status/getAllStatus',
    async () => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetAllDeviceStatus`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getDeviceStatusById = createAsyncThunk(
    'status/getDeviceStatusById',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetDeviceStatusById?statusSK=${loginIds.rowId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postStatus = createAsyncThunk(
    'status/postStatus',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Device/AddDeviceStatus?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const statusSlice = createSlice({
    name: 'status',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllStatus.pending, (state) => {
                state.statusListLoading = true;
            })
            .addCase(getAllStatus.fulfilled, (state, action) => {
                state.statusListLoading = false;
                const { result } = action.payload;
                state.allStatus = result?.deviceStatus || [];
            })
            .addCase(getAllStatus.rejected, (state, action) => {
                state.statusListLoading = false;
                state.allStatus = [];
                state.allStatus = action.error.message;
            })
            .addCase(getDeviceStatusById.pending, (state) => {
                state.statusRowLoading = true;
            })
            .addCase(getDeviceStatusById.fulfilled, (state, action) => {
                state.statusRowLoading = false;
                const { result } = action.payload;
                state.statusById = result?.deviceStatus || [];
            })
            .addCase(getDeviceStatusById.rejected, (state, action) => {
                state.statusRowLoading = false;
                state.statusById = [];
                state.statusById = action.error.message;
            })
            .addCase(postStatus.fulfilled, (state, action) => {
                state.formSuccess = action.payload;
            })
            .addCase(postStatus.rejected, (state, action) => {
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })
    },
});

export default statusSlice.reducer;