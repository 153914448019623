import React from "react";
import { Navigate } from "react-router-dom";
// redux
import {
    selectLoggedIn,
} from "src/features/loginSlice";
import { useSelector } from "react-redux";

const RequireGuest = ({ children }) => {
  const loggedIn = useSelector(selectLoggedIn);

  if (loggedIn) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

export default RequireGuest;
