import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from "../@core/utils/axios";

const initialState = {
    allDrumListLoading: false,
    allTypeListLoading: false,
    allModelListLoading: false,
    allStateListLoading: false,
    allCityListLoading: false,
    allCountryListLoading: false,
    allDrumList: [],
    allTypeList: [],
    allModelList: [],
    allStateList: [],
    allCityList: [],
    allCountryList: [],
    allCategoryList: [],
    allMakeList: [],
    allStatusList: [],
    allDrumList: [],
    error: "",
    allDeviceTypeList: []
};

export const getAllDrum = createAsyncThunk("common/getAllDrum", async () => {
    try {
        const { data } = await axiosApiInstance.get(`/Device/GetAllDrum`);
        return data;
    } catch (error) {
        ToastError(error.response.data.messageKey);
    }
});

export const getAllType = createAsyncThunk("common/getAllType", async (type) => {
    try {
        const { data } = await axiosApiInstance.get(`/Device/GetAllType?deviceType=${type}`);
        return data;
    } catch (error) {
        ToastError(error.response.data.messageKey);
    }
});

export const getAllMake = createAsyncThunk("common/getAllMake", async (type) => {
    try {
        const { data } = await axiosApiInstance.get(`/Device/GetAllMake?makeType=${type}`);
        return data;
    } catch (error) {
        ToastError(error.response.data.messageKey);
    }
});

export const getAllCategory = createAsyncThunk("common/getAllCategory", async (category) => {
    try {
        const { data } = await axiosApiInstance.get(`/Device/GetAllCategory?deviceType=${category}`);
        return data;
    } catch (error) {
        ToastError(error.response.data.messageKey);
    }
});
export const getAllModel = createAsyncThunk("common/getAllModel", async (model) => {
    try {
        const { data } = await axiosApiInstance.get(`/Device/GetAllModel?modelType=${model}`);
        return data;
    } catch (error) {
        ToastError(error.response.data.messageKey);
    }
});

export const getAllCountry = createAsyncThunk(
    "common/getAllCountry",
    async () => {
        try {
            const { data } = await axiosApiInstance.get(`/CityState/GetAllCountry`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getAllState = createAsyncThunk(
    "common/getAllState",
    async (countrySK) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/CityState/GetAllState?countrySK=${countrySK}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getAllCity = createAsyncThunk(
    "common/getAllCity",
    async (stateSK) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/CityState/GetCity?stateSK=${stateSK}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);
export const getAllStatus = createAsyncThunk(
    "common/getAllStatus",
    async () => {
        try {
            const { data } = await axiosApiInstance.get(
                `/Device/GetAllDeviceStatus`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);
export const getAllDeviceType = createAsyncThunk(
    "common/getAllDeviceType",
    async () => {
        try {
            const { data } = await axiosApiInstance.get(
                `/Device/GetAllDeviceType`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);
export const commonSlice = createSlice({
    name: "common",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllDrum.pending, (state) => {
                state.allDrumListLoading = true;
            })
            .addCase(getAllDrum.fulfilled, (state, action) => {
                state.allDrumListLoading = false;
                const { result } = action.payload;
                state.allDrumList = result.drums;
            })
            .addCase(getAllDrum.rejected, (state, action) => {
                state.allDrumListLoading = false;
                state.allDrumList = [];
                state.allDrumList = action.error.message;
            })
            .addCase(getAllType.pending, (state) => {
                state.allTypeListLoading = true;
            })
            .addCase(getAllType.fulfilled, (state, action) => {
                state.allTypeListLoading = false;
                const { result } = action.payload;
                state.allTypeList = result.types;
            })
            .addCase(getAllType.rejected, (state, action) => {
                state.allTypeListLoading = false;
                state.allTypeList = [];
                state.allTypeList = action.error.message;
            })
            .addCase(getAllModel.pending, (state) => {
                state.allModelListLoading = true;
            })
            .addCase(getAllModel.fulfilled, (state, action) => {
                state.allModelListLoading = false;
                const { result } = action.payload;
                state.allModelList = result.models;
            })
            .addCase(getAllModel.rejected, (state, action) => {
                state.allModelListLoading = false;
                state.allModelList = [];
                state.allModelList = action.error.message;
            })
            .addCase(getAllState.pending, (state) => {
                state.allStateListLoading = true;
            })
            .addCase(getAllState.fulfilled, (state, action) => {
                state.allStateListLoading = false;
                const { result } = action.payload;
                state.allStateList = result.states;
            })
            .addCase(getAllState.rejected, (state, action) => {
                state.allStateListLoading = false;
                state.allStateList = [];
                state.allStateList = action.error.message;
            })
            .addCase(getAllCity.pending, (state) => {
                state.allCityListLoading = true;
            })
            .addCase(getAllCity.fulfilled, (state, action) => {
                state.allCityListLoading = false;
                const { result } = action.payload;
                state.allCityList = result.cities;
            })
            .addCase(getAllCity.rejected, (state, action) => {
                state.allCityListLoading = false;
                state.allCityList = [];
                state.allCityList = action.error.message;
            })
            .addCase(getAllCountry.pending, (state) => {
                state.allCountryListLoading = true;
            })
            .addCase(getAllCountry.fulfilled, (state, action) => {
                state.allCountryListLoading = false;
                const { result } = action.payload;
                state.allCountryList = result.country;
            })
            .addCase(getAllCountry.rejected, (state, action) => {
                state.allCountryListLoading = false;
                state.allCountryList = [];
                state.allCountryList = action.error.message;
            })
            .addCase(getAllCategory.pending, (state) => {
                state.allCategoryListLoading = true;
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                state.allCategoryListLoading = false;
                const { result } = action.payload;
                state.allCategoryList = result.category;
            })
            .addCase(getAllCategory.rejected, (state, action) => {
                state.allCategoryListLoading = false;
                state.allCategoryList = [];
                state.allCategoryList = action.error.message;
            })
            .addCase(getAllMake.pending, (state) => {
                state.allMakeListLoading = true;
            })
            .addCase(getAllMake.fulfilled, (state, action) => {
                state.allMakeListLoading = false;
                const { result } = action.payload;
                state.allMakeList = result.make;
            })
            .addCase(getAllMake.rejected, (state, action) => {
                state.allMakeListLoading = false;
                state.allMakeList = [];
                state.allMakeList = action.error.message;
            })
            .addCase(getAllStatus.pending, (state) => {
                state.allStatusListLoading = true;
            })
            .addCase(getAllStatus.fulfilled, (state, action) => {
                state.allStatusListLoading = false;
                const { result } = action.payload;
                state.allStatusList = result.deviceStatus;
            })
            .addCase(getAllStatus.rejected, (state, action) => {
                state.allStatusListLoading = false;
                state.allStatusList = [];
                state.allStatusList = action.error.message;
            })
            .addCase(getAllDeviceType.pending, (state) => {
                state.allDeviceTypeListLoading = true;
            })
            .addCase(getAllDeviceType.fulfilled, (state, action) => {
                state.allDeviceTypeListLoading = false;
                const { result } = action.payload;
                state.allDeviceTypeList = result.allDeviceTypes;
            })
            .addCase(getAllDeviceType.rejected, (state, action) => {
                state.allDeviceTypeListLoading = false;
                state.allDeviceTypeList = [];
                state.allDeviceTypeList = action.error.message;
            });
    },
});

export default commonSlice.reducer;
