import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// forms
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getCompanies, getCompanyById } from "src/features/companySlice";
import { getProjectByIdV2, clearProjectById, createProject, updateProject } from "src/features/projectSlice";
// MUI
import {
    Box, Grid, FormControl, TextField, Autocomplete,
    InputLabel, Select, MenuItem,
    Card, CardContent, CardHeader
} from "@mui/material";
// assets
import { iconSet } from "src/@core/data/icons";
// components
import LoadingButton from 'src/components/LoadingButton';
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import { ToastSuccess, ToastError } from "src/components/Toast";
// data
import { usStates } from 'src/@core/data/us-states';
// util
import { formatDate } from "src/@core/utils/dateTimeFormatter";

// ----------------------------------------------------------------------

const defaultFormValues = {
    companySK: "",
    customerSK: "",
    projectID: "",
    projectName: "",
    state: "",
    city: "",
    zip: "",
    startDate: "",
    endDate: "",
    equipmentShift: "",
};

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "/projects", label: "Projects" },
    { route: "", label: "Details" },
];

// ----------------------------------------------------------------------

export default function ProjectFormPage() {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { allCompanies, companyById } = useSelector((state) => state.company);
    const { projectById } = useSelector((state) => state.project);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const params = new URLSearchParams(window.location.search);
    const paramsId = params.get("id");

    useEffect(() => {
        setCompanyOptions(allCompanies.map((company) => {
            return {
                value: company.companySK,
                label: company.companyName,
            };
        }));
    }, [allCompanies]);

    useEffect(() => {
        if (!companyById.customers) {
            //ToastError("Please add a customer record");
            return;
        }

        setCustomerOptions(
            companyById.customers.map((customer) => {
                const firstName = customer.firstName ? customer.firstName : "";
                const lastName = customer.lastName ? customer.lastName : "";

                return {
                    value: customer.customerSK,
                    label: `${firstName} ${lastName}`,
                };
            })
        );
    }, [companyById]);

    const validationSchema = Yup.object().shape({
        companySK: isSuperAdmin ? Yup.string().required().min(1, "Please select Company name!") : Yup.string(),
        customerSK: Yup.string().required().min(1, "Please select Customer name!"),
        projectID: Yup.string().required(),
        projectName: Yup.string().required(),
        state: Yup.string().required(),
        city: Yup.string().required(),
        zip: Yup.string().matches(/^[0-9-]*$/, 'Invalid zip').required(),
        startDate: Yup.string().required(),
        endDate: Yup.string().required().test("endDate", "End date must be greater than start date", function (value) {
            const { startDate } = this.parent;
            return !startDate || !value || new Date(value) >= new Date(startDate);
        }),
        equipmentShift: Yup.string(),
    });

    // react hook form
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validationSchema),
        defaultValues: defaultFormValues,
    });

    useEffect(() => {
        if(isSuperAdmin){
            dispatch(getCompanies());
        } else {
            dispatch(getCompanyById(loginRes.companyId));
        }

        if (paramsId) {
            dispatch(getProjectByIdV2(paramsId));
        } else {
            dispatch(clearProjectById());
        }
    }, [dispatch, paramsId]);

    useEffect(() => {
        if (projectById?.projectSK) {
            const dateInputFormat = "yyyy-MM-dd'T'HH:mm:ss";
            const startDate = projectById.startDate ? formatDate(projectById.startDate, dateInputFormat, 'yyyy-MM-dd') : "";
            const endDate = projectById.endDate ? formatDate(projectById.endDate, dateInputFormat, 'yyyy-MM-dd') : "";

            const formData = {
                companySK: projectById.companySK ? projectById.companySK : 0,
                customerSK: projectById.customerSK ? projectById.customerSK : 0,
                projectID: projectById.projectID ? projectById.projectID : "",
                projectName: projectById.projectName ? projectById.projectName : "",
                state: projectById.state ? projectById.state : "",
                city: projectById.city ? projectById.city : "",
                zip: projectById.zip ? projectById.zip : "",
                startDate: startDate,
                endDate: endDate,
                equipmentShift: projectById.equipmentShift ? projectById.equipmentShift : "",
            };

            reset(formData);

            dispatch(getCompanyById(projectById.companySK));
        } else {
            reset();
        }
    }, [projectById]);

    const handleCompanyChange = (value) => {
        if (value) {
            setCustomerOptions([]);
            dispatch(getCompanyById(value));
        }
    };

    const onSubmit = (data) => {
        setSubmitting(true);

        if (!paramsId) {
            dispatch(createProject(data)).then((res) => {
                if (res.payload?.data?.projectSK) {
                    ToastSuccess('Project saved');

                    // This won't actually update the browser history, but will update the URL
                    navigate(`/projects/form?id=${res.payload.data.projectSK}`);
                } else {
                    ToastError('An unexpected error occurred.');
                }

                setSubmitting(false);
            });
        } else {
            dispatch(updateProject({ id: paramsId, data: data })).then((res) => {
                if (res.payload?.data?.companySK) {
                    ToastSuccess('Project saved');
                }

                setSubmitting(false);
            });
        }
    };

    return (
        <Box className="d-flex flex-column px-md-4 form-layout">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Project Details" breadcrumbs={breadcrumbs} icon={iconSet.company} />
                </Box>
            </Box>

            <Card>
                <CardHeader title="Project" />

                <CardContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6} lg={4}>
                                {isSuperAdmin && (
                                    <Box>
                                        <Controller
                                            name="companySK"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value } }) => (
                                                <Autocomplete
                                                    noOptionsText="Select Value"
                                                    options={companyOptions}
                                                    getOptionLabel={option => option.label}
                                                    value={companyOptions.find(item => item.value === value) || null}
                                                    onChange={(event, newValue) => {
                                                        onChange(newValue ? newValue.value : '');
                                                        handleCompanyChange(newValue ? newValue.value : '');
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField label="Company" {...params} />
                                                    }
                                                >
                                                </Autocomplete>
                                            )}
                                        />
                                        {errors.companySK && (
                                            <p className="text-error">Company is required</p>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                            <Grid item sm={12} md={6} lg={8} />

                            <Grid item sm={12} md={6} lg={4}>
                                <Box>
                                    <Controller
                                        name="customerSK"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <Autocomplete
                                                noOptionsText="Select Value"
                                                options={customerOptions}
                                                getOptionLabel={option => option.label}
                                                value={customerOptions.find(item => item.value === value) || null}
                                                onChange={(event, newValue) => {
                                                    onChange(newValue ? newValue.value : '');
                                                }}
                                                renderInput={(params) =>
                                                    <TextField label="Customer" {...params} />
                                                }
                                                disabled={customerOptions.length < 1}
                                            >
                                            </Autocomplete>
                                        )}
                                    />
                                    {errors.customerSK && (
                                        <p className="text-error">Customer is required</p>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={6} lg={8} />

                            <Grid item sm={12} md={6} lg={4}>
                                <Controller
                                    name="projectName"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Project Name" />
                                    )}
                                />
                            </Grid>

                            <Grid item sm={12} md={6} lg={4}>
                                <Controller
                                    name="projectID"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Project Id" />
                                    )}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} lg={4} />

                            <Grid item sm={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Start Date"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </FormControl>
                                {errors.startDate && (
                                    <p className="text-error">Start date is required</p>
                                )}
                            </Grid>

                            <Grid item sm={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <Controller
                                        name="endDate"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="End Date"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </FormControl>
                                {errors.endDate && (
                                    <p className="text-error">{errors.endDate.message}</p>
                                )}
                            </Grid>
                            <Grid item sm={12} md={12} lg={4} />

                            <Grid item sm={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="shift-label">Shift</InputLabel>
                                    <Controller
                                        name="equipmentShift"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                labelId="shift-label"
                                                label="Shift"
                                            >
                                                <MenuItem value="F">Full-Time</MenuItem>
                                                <MenuItem value="M">Morning</MenuItem>
                                                <MenuItem value="N">Night</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={6} lg={8} />

                            <Grid item sm={12} md={6} lg={4}>
                                <Box>
                                    <Controller
                                        name="state"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <Autocomplete
                                                noOptionsText="Select Value"
                                                options={usStates}
                                                getOptionLabel={option => option.label}
                                                value={usStates.find(item => item.key === value) || null}
                                                onChange={(event, newValue) => {
                                                    onChange(newValue ? newValue.key : '');
                                                }}
                                                renderInput={(params) =>
                                                    <TextField label="State" {...params} />
                                                }
                                            >
                                            </Autocomplete>
                                        )}
                                    />
                                    {errors.state && (
                                        <p className="text-error">State is required</p>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item sm={12} md={6} lg={4}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="City" />
                                    )}
                                />
                            </Grid>

                            <Grid item sm={12} md={6} lg={4}>
                                <Controller
                                    name="zip"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Zip" />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>
                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={submitting}
                                    sx={{ width: '110px' }}
                                >
                                    Save
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
};

