import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';

export default function ActionMenu({ options, secondOptions, handleMenuClick, onClick, onClose}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);

      if (onClick) {
        onClick(event);
      }
    };

    const handleClose = (e) => {
        if (onClose) {
            onClose(e);
        }

        setAnchorEl(null);
    };

    const handleItemClick = (option, event) => {
        setAnchorEl(null);
        handleMenuClick(option, event);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="action-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <MenuItem key={option} onClick={(e) => handleItemClick(option, e)}>
                        {option}
                    </MenuItem>
                ))}

                {secondOptions && <Divider /> }
                {secondOptions && secondOptions.map((option) => (
                    <MenuItem key={option} onClick={(e) => handleItemClick(option, e)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}