import React from 'react';
import { Box, Typography, Paper, IconButton, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Login } from '@mui/icons-material';

const IpcDisplay = ({ ipc, onEdit }) => {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;

    return (
        <Box>
            <Paper elevation={1} sx={{ p: 2, maxWidth: 300 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" align="center">IPC</Typography>

                    {isSuperAdmin && (
                        <IconButton 
                            color="primary"
                            size="small"
                            onClick={() => onEdit(ipc)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton >
                    )}
                </Box>

                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Make:</Typography>
                    <Typography variant="body2">{ipc.make}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Model:</Typography>
                    <Typography variant="body2">{ipc.model}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">Serial Number:</Typography>
                    <Typography variant="body2">{ipc.serialNumber}</Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default IpcDisplay;