import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    plannerListLoading: false,
    allPlannerList: [],
    error: '',
    projectPlannerLoading:false,
    allProjectPlannerList: []
};

export const getDashboardData = createAsyncThunk(
    'planner/getDashboardData',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/User/GetDashboardData?companyId=${loginIds.companyId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getProjectDashboardData = createAsyncThunk(
    'planner/getProjectDashboardData',
    async (pId) => {
        try {
            const loginIds = JSON.parse(localStorage.getItem("loginRes"));
            const { data } = await axiosApiInstance.get(`/User/GetProjectDashboardData?projectSK=${pId}&companyId=${loginIds.companyId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const plannerSlice = createSlice({
    name: 'planner',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.pending, (state) => {
                state.plannerListLoading = true;
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                state.plannerListLoading = false;
                const { result } = action.payload;
                state.allPlannerList = result;
            })
            .addCase(getDashboardData.rejected, (state, action) => {
                state.plannerListLoading = false;
                state.allPlannerList = [];
                state.allPlannerList = action.error.message;
            })
            .addCase(getProjectDashboardData.pending, (state) => {
                state.projectPlannerLoading = true;
            })
            .addCase(getProjectDashboardData.fulfilled, (state, action) => {
                state.projectPlannerLoading = false;
                const { result } = action.payload;
                state.allProjectPlannerList = result;
            })
            .addCase(getProjectDashboardData.rejected, (state, action) => {
                state.projectPlannerLoading = false;
                state.allProjectPlannerList = [];
                state.allProjectPlannerList = action.error.message;
            })
        getProjectDashboardData
    },
});

export default plannerSlice.reducer;
