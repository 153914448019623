import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHexbinLayers,
  setLayerRanges,
  selectPwlRange,
  setPwlRange,
  setColorRange,
} from "src/features/densityMapSlice";
// MUI
import {
  Box, Grid, Typography, Button, Slider, TextField, IconButton,
  FormControlLabel, RadioGroup, Radio, FormLabel,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Alert
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
// config
import {
  DEFAULT_HEXBIN_LAYERS,
  DEFAULT_PWL_RANGE,
  DEFAULT_COLOR_RANGE,
} from "src/features/densityMapSlice";

// ----------------------------------------------------------------------

const DENSITY_COLORS = DEFAULT_HEXBIN_LAYERS[0].layer_info.color_range;
const PASS_COUNT_COLORS = DEFAULT_HEXBIN_LAYERS[1].layer_info.color_range;
const TEMPERATURE_COLORS = DEFAULT_HEXBIN_LAYERS[2].layer_info.color_range;

const DENSITY_RANGE = DENSITY_COLORS.filter((_, i) => i % 2 === 0);
const PASS_COUNT_RANGE = PASS_COUNT_COLORS.filter((_, i) => i % 2 === 0);
const TEMPERATURE_RANGE = TEMPERATURE_COLORS.filter((_, i) => i % 2 === 0);

const DENSITY_MARKS = [
  {
    value: DENSITY_RANGE[0],
    label: `${DENSITY_RANGE[0]}`,
  },
  {
    value: DENSITY_RANGE[DENSITY_RANGE.length - 1],
    label: `${DENSITY_RANGE[DENSITY_RANGE.length - 1]}`,
  }
];

const PASS_COUNT_MARKS = [
  {
    value: PASS_COUNT_RANGE[0],
    label: `${PASS_COUNT_RANGE[0]}`,
  },
  {
    value: PASS_COUNT_RANGE[PASS_COUNT_RANGE.length - 1],
    label: `${PASS_COUNT_RANGE[PASS_COUNT_RANGE.length - 1]}`,
  }
];

const TEMPERATURE_MARKS = [
  {
    value: TEMPERATURE_RANGE[0],
    label: `${TEMPERATURE_RANGE[0]}`,
  },
  {
    value: TEMPERATURE_RANGE[TEMPERATURE_RANGE.length - 1],
    label: `${TEMPERATURE_RANGE[TEMPERATURE_RANGE.length - 1]}`,
  }
];

const DEFAULT_FORM_DATA = {
  density_range: [DENSITY_RANGE[0], DENSITY_RANGE[DENSITY_RANGE.length - 1]],
  pass_count_range: [PASS_COUNT_RANGE[0], PASS_COUNT_RANGE[PASS_COUNT_RANGE .length - 1]],
  temperature_range: [TEMPERATURE_RANGE[0], TEMPERATURE_RANGE[TEMPERATURE_RANGE.length - 1]],
  pwl_range: DEFAULT_PWL_RANGE,
  color_range: DEFAULT_COLOR_RANGE,
};

export default function SettingsFormDialog(props) {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
  const hexbinLayers = useSelector(selectHexbinLayers);
  const pwlRange = useSelector(selectPwlRange);

  useEffect(() => {
    // set form values from redux store
    if (hexbinLayers && hexbinLayers.length > 0) {
      const densityRange = hexbinLayers[0].layer_info.color_range.filter((_, i) => i % 2 === 0);
      const passCountRange = hexbinLayers[1].layer_info.color_range.filter((_, i) => i % 2 === 0);
      const temperatureRange = hexbinLayers[2].layer_info.color_range.filter((_, i) => i % 2 === 0);

      setFormData({
        ...formData,
        density_range: [densityRange[0], densityRange[densityRange.length - 1]],
        pass_count_range: [passCountRange[0], passCountRange[passCountRange.length - 1]],
        temperature_range: [temperatureRange[0], temperatureRange[temperatureRange.length - 1]],
      });
    }
  }, [hexbinLayers]);

  useEffect(() => {
    if (!pwlRange) return;

    setFormData({
      ...formData, pwl_range: pwlRange
    });
  }, [pwlRange]);

  const handleClose = () => {
    onClose();
  };

  const handleRangeChange = (event, rangeName, newValue) => {
    setErrorMsg('');

    setFormData({
      ...formData, [rangeName]: newValue
    });
  };

  const handlePwlChange = (event, rangeIndex) => {
    const value = event.target.value;
    const newPwlRange = [...formData.pwl_range];
    newPwlRange[rangeIndex] = value;

    setFormData({
      ...formData, pwl_range: newPwlRange
    });
  }

  const handleColorChange = (event) => {
    const value = event.target.value;

    setFormData({
      ...formData, color_range: value
    });
  };

  const getFormErrorMsg = () => {
    let errorMsg = '';

    const { density_range, pass_count_range, temperature_range, pwl_range } = formData;

    if (density_range[0] === density_range[1]) {
      errorMsg = 'Invalid density range';
    } else if (pass_count_range[0] === pass_count_range[1]) {
      errorMsg = 'Invalid pass count range';
    } else if (temperature_range[0] === temperature_range[1]) {
      errorMsg = 'Invalid temperature range';
    }

    if (isNaN(pwl_range[0]) || isNaN(pwl_range[1])) {
      errorMsg = 'Invalid PWL range';
    } else if (pwl_range[0] === '' || pwl_range[1] === '') {
      errorMsg = 'Invalid PWL range';
    }

    console.log(pwl_range);

    return errorMsg;
  }

  const setDefaultValues = () => {
    setFormData(DEFAULT_FORM_DATA);
  }

  const onSubmit = (event) => {
    event.preventDefault();

    const formErrorMsg = getFormErrorMsg();
    if (formErrorMsg) {
      setErrorMsg(formErrorMsg);
      return;
    } else {
      setErrorMsg('');
    }

    const { density_range, pass_count_range, temperature_range } = formData;

    const minDensityIndex = DENSITY_RANGE.indexOf(density_range[0]);
    const maxDensityIndex = DENSITY_RANGE.indexOf(density_range[1]);
    const densityRange = DENSITY_RANGE.slice(minDensityIndex, maxDensityIndex + 1);

    const minPassCountIndex = PASS_COUNT_RANGE.indexOf(pass_count_range[0]);
    const maxPassCountIndex = PASS_COUNT_RANGE.indexOf(pass_count_range[1]);
    const passCountRange = PASS_COUNT_RANGE.slice(minPassCountIndex, maxPassCountIndex + 1);

    const minTemperatureIndex = TEMPERATURE_RANGE.indexOf(temperature_range[0]);
    const maxTemperatureIndex = TEMPERATURE_RANGE.indexOf(temperature_range[1]);
    const temperatureRange = TEMPERATURE_RANGE.slice(minTemperatureIndex, maxTemperatureIndex + 1);

    // create maplibre paint color ranges
    let newDensityRange = densityRange.map((value, i) => {
      const index = DENSITY_COLORS.indexOf(value);
      const color = DENSITY_COLORS[index + 1];

      return [value, color];
    });
    newDensityRange = newDensityRange.flat();

    let newPassCountRange = passCountRange.map((value, i) => {
      const index = PASS_COUNT_COLORS.indexOf(value);
      const color = PASS_COUNT_COLORS[index + 1];

      return [value, color];
    });
    newPassCountRange = newPassCountRange.flat();

    let newTemperatureRange = temperatureRange.map((value, i) => {
      const index = TEMPERATURE_COLORS.indexOf(value);
      const color = TEMPERATURE_COLORS[index + 1];

      return [value, color];
    });
    newTemperatureRange = newTemperatureRange.flat();

    dispatch(setLayerRanges({
      densityRange: newDensityRange,
      passCountRange: newPassCountRange,
      termperatureRange: newTemperatureRange,
    }));

    const newPwlRange = formData.pwl_range.map(r => parseFloat(r));

    dispatch(setPwlRange(newPwlRange));

    dispatch(setColorRange(formData.color_range));

    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      value="md"
      PaperProps={{
        component: 'form',
        onSubmit: onSubmit
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Settings</DialogTitle>
        <IconButton onClick={handleClose} sx={myStyle.closeBtn}><CloseIcon/></IconButton>
      </Box>
      <DialogContent>
        {formData && (
          <Box>
            <Box>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }} gutterBottom>Density Range <small>(% MTD)</small></Typography>
              <Slider
                getAriaLabel={() => 'Density Range'}
                value={formData.density_range}
                onChange={(event, newValue) => handleRangeChange(event, 'density_range', newValue)}
                valueLabelDisplay="auto"
                min={DENSITY_MARKS[0].value}
                max={DENSITY_MARKS[DENSITY_MARKS.length - 1].value}
                marks={DENSITY_MARKS}
              />
            </Box>
            <Box>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }} gutterBottom>Pass Count Range</Typography>
              <Slider
                getAriaLabel={() => 'Pass Count Range'}
                value={formData.pass_count_range}
                onChange={(event, newValue) => handleRangeChange(event, 'pass_count_range', newValue)}
                valueLabelDisplay="auto"
                min={PASS_COUNT_MARKS[0].value}
                max={PASS_COUNT_MARKS[PASS_COUNT_MARKS.length - 1].value}
                step={2}
                marks={PASS_COUNT_MARKS}
              />
            </Box>
            <Box>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}  gutterBottom>Termperature Range <small>({'\u00B0F'})</small></Typography>
              <Slider
                getAriaLabel={() => 'Temperature Range'}
                value={formData.temperature_range}
                onChange={(event, newValue) => handleRangeChange(event, 'temperature_range', newValue)}
                valueLabelDisplay="auto"
                min={TEMPERATURE_MARKS[0].value}
                max={TEMPERATURE_MARKS[TEMPERATURE_MARKS.length - 1].value}
                marks={TEMPERATURE_MARKS}
                step={30}
              />
            </Box>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <TextField
                  sx={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                  label="PWL Low"
                  value={formData.pwl_range[0]}
                  onChange={(event) => handlePwlChange(event, 0)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                  label="PWL High"
                  value={formData.pwl_range[1]}
                  onChange={(event, newValue) => handlePwlChange(event, 1)}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 2 }}>
              <FormLabel>Color Options</FormLabel>
              <RadioGroup
                sx={{ flexDirection: 'row' }}
                name="color_range"
                value={formData.color_range}
                onChange={handleColorChange}
              >
                <FormControlLabel value="default_color_range" control={<Radio />} label="Color 1" />
                <FormControlLabel value="accessible_color_range" control={<Radio />} label="Color 2" />
              </RadioGroup>
            </Box>

            <Box sx={{ height: '30px' }}>
              {errorMsg && (
                <Alert severity="error">{errorMsg}</Alert>
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={setDefaultValues}>Restore Defaults</Button>
        <Button variant="contained" type="submit">Save</Button>
      </DialogActions>
    </Dialog>
  );
}

const myStyle = {
  closeBtn: {
    height: '30px',
    width: '30px',
    marginTop: '18px',
    marginRight: '18px',
  }
}