import React from "react";
import { useNavigate } from "react-router-dom";
// MUI
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from "@mui/material/ListItemText";
// components
import NavListItem from "./NavListItem";
import { iconSet } from "../../@core/data/icons";
import { permission } from "../../@core/data/permission";

const Navigation = ({ onItemClick }) => {
    const navigate = useNavigate();

    const handleClick = (path) => {
        if(onItemClick) {
            onItemClick();
        }
        
        navigate(path);
    }

    return (
        <>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className="nav-container"
            >
                <ListItemButton className="listnav-bg" onClick={() => handleClick("/home")}>
                    <span className="material-symbols-outlined">{iconSet.dashboard}</span>
                    <ListItemText primary={"Home"} className="listnav-bg-txt" />
                </ListItemButton>
                
                <NavListItem pagename={"Companies"} pageurl={"/companies"} iconname={iconSet.company} rolesPermission={permission.company} onClick={onItemClick} />
                <NavListItem pagename={"Customers"} pageurl={"/customers"} iconname={iconSet.customer} rolesPermission={permission.customer} onClick={onItemClick} />
                <NavListItem pagename={"Projects"} pageurl={"/projects"} iconname={iconSet.project} rolesPermission={permission.project} onClick={onItemClick} />
                <NavListItem pagename={"Equipment"} pageurl={"/equipment"} iconname={iconSet.equipment} rolesPermission={permission.equipment} onClick={onItemClick} />
                <NavListItem pagename={"Systems"} pageurl={"/systems"} iconname={iconSet.system} rolesPermission={permission.system} onClick={onItemClick} />
                <NavListItem pagename={"Data Keys"} pageurl={"/data-keys"} iconname={iconSet.dataKey} rolesPermission={permission.system} onClick={onItemClick} />
                <NavListItem pagename={"Users"} pageurl={"/users"} iconname={iconSet.users} rolesPermission={permission.user} onClick={onItemClick} />
            </List>
        </>

    );
};

export default Navigation;
