import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    dataKeys: [],
    unassignedDataKeys: [],
    createdDataKey: {},
    dataKeyById: {},
    dataKeyByKey: {},
    error: '',
    loading: false,
    getByIdLoading: false,
    loadingUnassigned: false,
};

export const getAllDataKeys = createAsyncThunk(
    'dataKey/getAllDataKeys',
    async () => {
        try {
            const url = '/api/v2/data-keys';
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getUnassignedDataKeys = createAsyncThunk(
    'dataKey/getUnassignedDataKeys',
    async (data = { companyId: null }) => {
        try {
            let url = `/api/v2/data-keys/unassigned?`;

            if (data.companyId) {
                url += `companyId=${data.companyId}&`;
            }

            url = url.slice(0, -1); // Remove the trailing '&' or '?'

            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const createDataKey = createAsyncThunk(
    'dataKey/createDataKey',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/data-keys`, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getDataKeyById = createAsyncThunk(
    'dataKey/getDataKeyById',
    async (id) => {
        try {
            const url = `/api/v2/data-keys/${id}`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getDataKeyByKey = createAsyncThunk(
    'dataKey/getDataKeyByKey',
    async (id) => {
        try {
            const url = `/api/v2/data-keys/key/${id}`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const updateDataKey = createAsyncThunk(
    'dataKey/updateDataKey',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/data-keys/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const deleteDataKey = createAsyncThunk(
    'dataKey/deleteDataKey',
    async (id) => {
        try {
            const url = `/api/v2/data-keys/${id}`;
            const response = await axiosApiInstance.delete(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const dataKeySlice = createSlice({
    name: 'dataKey',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllDataKeys.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDataKeys.fulfilled, (state, action) => {
                state.loading = false;
                state.dataKeys = action.payload.data;
            })
            .addCase(getAllDataKeys.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getUnassignedDataKeys.pending, (state) => {
                state.loadingUnassigned = true;
            })
            .addCase(getUnassignedDataKeys.fulfilled, (state, action) => {
                state.loadingUnassigned = false;
                state.unassignedDataKeys = action.payload.data;
            })
            .addCase(getUnassignedDataKeys.rejected, (state, action) => {
                state.loadingUnassigned = false;
                state.error = action.error.message;
            })

            .addCase(createDataKey.pending, (state) => {
                state.loading = true;
            })
            .addCase(createDataKey.fulfilled, (state, action) => {
                state.loading = false;
                state.createdDataKey = action.payload.data;
                state.dataKeys.push(action.payload.data);
            })
            .addCase(createDataKey.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getDataKeyById.pending, (state) => {
                state.getByIdLoading = true;
            })
            .addCase(getDataKeyById.fulfilled, (state, action) => {
                state.getByIdLoading = false;
                state.dataKeyById = action.payload.data;
            })
            .addCase(getDataKeyById.rejected, (state, action) => {
                state.getByIdLoading = false;
                state.dataKeyById = {};
                state.error = action.error.message;
            })

            .addCase(getDataKeyByKey.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDataKeyByKey.fulfilled, (state, action) => {
                state.loading = false;
                state.dataKeyByKey = action.payload.data;
            })
            .addCase(getDataKeyByKey.rejected, (state, action) => {
                state.loading = false;
                state.dataKeyByKey = {};
                state.error = action.error.message;
            })

            .addCase(updateDataKey.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateDataKey.fulfilled, (state, action) => {
                state.loading = false;

                const updatedDataKey = action.payload.data;
                const index = state.dataKeys.findIndex((dataKey) => dataKey.id === updatedDataKey.id);

                if (index !== -1) {
                    state.dataKeys[index] = updatedDataKey;
                }
            })
            .addCase(updateDataKey.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(deleteDataKey.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteDataKey.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteDataKey.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default dataKeySlice.reducer;
