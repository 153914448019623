import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    makeListLoading: false,
    makeRowLoading: false,
    allMake: [],
    makeById: [],
    formSuccess: [],
    error: ''
};

export const getAllDeviceMake = createAsyncThunk(
    'make/getAllDeviceMake',
    async () => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetAllMake?makeType=A`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getDeviceMakeById = createAsyncThunk(
    'make/getDeviceMakeById',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetDeviceMakeById?makeDeviceSK=${loginIds.rowId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postMake = createAsyncThunk(
    'make/postMake',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Device/AddMake?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const makeSlice = createSlice({
    name: 'make',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeviceMake.pending, (state) => {
                state.makeListLoading = true;
            })
            .addCase(getAllDeviceMake.fulfilled, (state, action) => {
                state.makeListLoading = false;
                const { result } = action.payload;
                state.allMake = result?.make || [];
            })
            .addCase(getAllDeviceMake.rejected, (state, action) => {
                state.makeListLoading = false;
                state.allMake = [];
                state.allMake = action.error.message;
            })
            .addCase(getDeviceMakeById.pending, (state) => {
                state.makeRowLoading = true;
            })
            .addCase(getDeviceMakeById.fulfilled, (state, action) => {
                state.makeRowLoading = false;
                const { result } = action.payload;
                state.makeById = result?.make || [];
            })
            .addCase(getDeviceMakeById.rejected, (state, action) => {
                state.makeRowLoading = false;
                state.makeById = [];
                state.makeById = action.error.message;
            })
            .addCase(postMake.fulfilled, (state, action) => {
                state.formSuccess = action.payload;
            })
            .addCase(postMake.rejected, (state, action) => {
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })
    },
});

export default makeSlice.reducer;