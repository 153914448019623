import { useEffect, useState } from 'react';
// MUI
import { styled } from "@mui/material/styles";
import {
  Box, Typography, IconButton,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// util
import { formatDate } from "src/@core/utils/dateTimeFormatter";
import { getAveragePassIdPoint } from "src/@core/utils/mapUtils";

// ----------------------------------------------------------------------

const ScrollBox = styled("div")(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '7px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f0f0f0',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#c1c1c1',
  },
}));

const PanelCell = styled(TableCell)(({ theme }) => ({
  padding: '2px 6px',
  fontSize: '0.9rem',
}));

// ----------------------------------------------------------------------

const MapInfoPanel = (props) => {
  const { hexbin, points, onClose } = props;
  const [hexbinPoints, setHexbinPoints] = useState([]);

  useEffect(() => {
    if (!hexbin || !points) return;

    // if a string, convert to json
    let pointIds = hexbin.properties.point_ids;
    if (typeof pointIds === 'string') {
      pointIds = JSON.parse(pointIds);
    }
    pointIds = Object.keys(pointIds);

    // get the points in the hexbin
    let pointsInHexbin = pointIds.map((id) => {
      return points.features.find((point) => point.properties.id === id);
    });

    let passIDs = [...new Set(pointsInHexbin.map((point) => point.properties.passID))];

    pointsInHexbin = passIDs.map((passID) => {
      return getAveragePassIdPoint(passID, pointsInHexbin);
    });

    // reverse chronological order
    pointsInHexbin.sort((a, b) => new Date(b.properties.time) - new Date(a.properties.time));

    setHexbinPoints(pointsInHexbin);
  }, [hexbin, points]);

  return (
    <Box
      sx={{
        p: 1,
        background: '#ffffff7d',
        borderRadius: '3px',
        border: '1px solid #a1a1a1',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle2" sx={{ mb: 1, textAlign: 'left', fontWeight: 'bold', fontSize: '0.8rem' }}>
          Hexbin Details
        </Typography>
        <IconButton sx={{ color: 'rgba(0, 0, 0, 0.7)' }} size="small" onClick={onClose}>
          <CloseIcon sx={{ fontSize: '16px' }} />
        </IconButton>
      </Box>
      <ScrollBox sx={{ width: '280px', height: 'calc(100% - 30px)', overflowY: 'auto' }}>
        {hexbinPoints && hexbinPoints.map((point, index) => (
          <PointInfo key={index} point={point} index={hexbinPoints.length - index} /> 
        ))}
      </ScrollBox>
    </Box>
  );
};

export default MapInfoPanel;

function PointInfo(props) {
  const { point, index } = props;

  const microDateFormatter = (dateStr) => {
    if (!dateStr) return "N/A";

    try {
      // The API is returning inconsistent datestring formats. In addition it sometimes returns 
      //   microseconds in the datetime string and date-fns does not support microseconds. 
      //   To handle this we're truncating the string to a consistent format.
      const dateStrShortened = (dateStr.length > 25) ? dateStr.substring(0, 19) + dateStr.slice(-6) : dateStr;
      const inputFormatAdjusted = 'yyyy-MM-dd HH:mm:ssXXX';
      const outputFormat = 'M/d/yyyy HH:mm:ss a';
      const newDateStr = formatDate(dateStrShortened, inputFormatAdjusted, outputFormat);

      if (!newDateStr) {
        console.error(`An error occurred formatting date: ${dateStr}`);
        return "-";
      }

      return newDateStr;
    } catch (error) {
      console.error(`An error occurred formatting date: ${dateStr} error: ${error}`);
      return "-";
    }
  }

  const formatNumber = (n, decimalPlaces) => {
    let asDecimal = Number(n.toFixed(decimalPlaces));
    let asString = asDecimal.toString();

    // Check if there's a decimal part to avoid adding unnecessary decimal point
    if (asString.indexOf('.') > -1) {
      return asString.replace(/(\.\d*?[1-9])0+$/, "$1");
    } else {
      return asString;
    }
  }

  return (
    <Box sx={{ mb: 1, mr: 1 }}>
      <TableContainer component={Paper}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell colSpan="2" sx={{ color: 'white', padding: '6px 6px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box style={{ fontSize: '0.92rem' }}>Pass: {index}</Box>
                  <Typography sx={{ fontSize: '0.68rem' }}>* Average</Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <PanelCell>Time</PanelCell>
              <PanelCell>{microDateFormatter(point.properties.time)}</PanelCell>
            </TableRow>
            <TableRow>
              <PanelCell>Density*</PanelCell>
              <PanelCell>{point.properties.density.toFixed(1)} <small>%MTD</small></PanelCell>
              {/* <PanelCell>{formatNumber(point.properties.density, 1)} <small>%MTD</small></PanelCell> */}
            </TableRow>
            <TableRow>
              <PanelCell>Temp*</PanelCell>
              <PanelCell>{point.properties.temperature.toFixed(0)} <small>{'\u00B0F'}</small></PanelCell>
            </TableRow>
            <TableRow>
              <PanelCell>Speed*</PanelCell>
              <PanelCell>{formatNumber(point.properties.speed, 1)} <small>mph</small></PanelCell>
            </TableRow>
            <TableRow>
              <PanelCell>Heading</PanelCell>
              <PanelCell>{point.properties.heading_direction}</PanelCell>
            </TableRow>
            <TableRow>
              <PanelCell>Coords</PanelCell>
              <PanelCell>{point.geometry.coordinates[1]}, {point.geometry.coordinates[0]}</PanelCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
