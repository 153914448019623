import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from "../@core/utils/axios";

const initialState = {
    loading: false,
    projectsLoading: false,
    projectById: {},
    projectEquipLoading: false,
    viewDensityByProjectLoading: false,
    allProjects: [],
    projectEquipRelation: [],
    customerId: [],
    viewDensityByProject: [],
    error: "",
    allProjectEquipment: [],
    projectEquipmentLoading: false,
};

export const getAllProjectList = createAsyncThunk(
    "project/getAllProjectList",
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/Project/GetAllProject?companyId=${loginIds.companyId}&userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getAllProjectEquipmentList = createAsyncThunk(
    "project/getAllProjectEquipmentList",
    async (projectData) => {
        try {
            const loginIds = JSON.parse(localStorage.getItem("loginRes"));
            const { data } = await axiosApiInstance.get(
                `/Project/GetAllProjectEquipment?projectId=${projectData.projectSK}&startDate=${projectData.startDate}&endDate=${projectData.endDate}&companyId=${projectData.companySK}&userId=${loginIds.userId}&shift=${projectData.shift}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getProjectEquipRelation = createAsyncThunk(
    "project/getProjectEquipRelation",
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/Project/GetProjectEquipRelation?projectId=${loginIds.projectId}&companyId=${loginIds.companyId}&userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getProjectById = createAsyncThunk(
    "project/getProjectById",
    async (ids) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/Project/GetProject?projectId=${ids.rowId}&userId=${ids.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getCustomerId = createAsyncThunk(
    "project/getCustomerId",
    async (compId) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const { data } = await axiosApiInstance.get(
                `/Customer/GetAllCustomerByName?companyId=${compId}&userId=${loginId.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postProjectList = createAsyncThunk(
    "project/postProjectList",
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(
                `/Project/Save?userId=${loginId.userId}`,
                data
            );
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postAddDensity = createAsyncThunk(
    "project/postAddDensity",
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(
                `/Project/AddUpdateCoreDensity?userId=${loginId.userId}`,
                data
            );
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getDensityByProjectName = createAsyncThunk(
    "project/getDensityByProjectName",
    async (projectDetails) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const { data } = await axiosApiInstance.get(
                `/Project/GetCoreDesityInfo?companySK=${projectDetails.companySK}&projectKey=${projectDetails.projectKey}&userId=${loginId.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

// API V2

export const getAllProjects = createAsyncThunk(
    'project/getAllProjects',
    async (data = { companyId: null }) => {
        try {
            let url = `/api/v2/projects?`;

            if (data.companyId) {
                url += `companyId=${data.companyId}&`;
            }

            url = url.slice(0, -1); // Remove the trailing '&' or '?'

            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
            throw error;
        }
    }
);

export const getProjectByIdV2 = createAsyncThunk(
    "project/getProjectByIdV2",
    async (id) => {
        try {
            const { data } = await axiosApiInstance.get(`/api/v2/projects/${id}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const clearProjectById = createAsyncThunk(
    'project/clearProjectById',
    async () => {
        return null;
    }
);

export const createProject = createAsyncThunk(
    'project/createProject',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/projects`, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const updateProject = createAsyncThunk(
    'project/updateProject',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/projects/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const deleteProject = createAsyncThunk(
    'project/deleteProject',
    async (id) => {
        try {
            const url = `/api/v2/project/${id}`;
            const response = await axiosApiInstance.delete(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const assignEquipment = createAsyncThunk(
    'project/assignEquipment',
    async (data) => {
        const { projectId, equipmentId } = data;

        try {
            const url = `/api/v2/projects/${projectId}/equipment/${equipmentId}`;
            const response = await axiosApiInstance.post(url, data);
            return response;
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                ToastError('Error: ' + error.response.data.error.message);
            } else {
                ToastError(error.message);
            }
        }
    }
);

export const removeEquipment = createAsyncThunk(
    'project/removeEquipment',
    async (data) => {
        const { projectId, equipmentId } = data;

        try {
            const url = `/api/v2/projects/${projectId}/equipment/${equipmentId}`;
            const response = await axiosApiInstance.delete(url, data);
            return response;
        } catch (error) {
            if (error?.reponse?.data?.error?.message) {
                ToastError('Error: ' + error.reponse.data. error.message);
            } else {
                ToastError(error.message);
            }
        }
    }
);

export const projectSlice = createSlice({
    name: "project",
    initialState,
    extraReducers: (builder) => {
        builder
            // V2
            .addCase(getAllProjects.pending, (state) => {
                state.projectsLoading = true;
            })
            .addCase(getAllProjects.fulfilled, (state, action) => {
                state.projectsLoading = false;
                state.allProjects = action.payload.data;
            })
            .addCase(getAllProjects.rejected, (state, action) => {
                state.projectsLoading = false;
                state.allProjects = [];
                state.error = action.error.message;
            })

            .addCase(getProjectByIdV2.pending, (state) => {
                state.projectsLoading = true;
            })
            .addCase(getProjectByIdV2.fulfilled, (state, action) => {
                state.projectsLoading = false;
                state.projectById = action.payload;
            })
            .addCase(getProjectByIdV2.rejected, (state, action) => {
                state.projectsLoading = false;
                state.projectById = {};
                state.error = action.error.message;
            })

            .addCase(clearProjectById.fulfilled, (state) => {
                console.log('clear project fulfilled');
                state.projectById = {};
            })

            .addCase(createProject.pending, (state) => {
                state.loading = true;
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload?.data;
            })
            .addCase(createProject.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(updateProject.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload.data;
            })
            .addCase(updateProject.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(deleteProject.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(assignEquipment.pending, (state) => {
                state.loading = true;
            })
            .addCase(assignEquipment.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(assignEquipment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(removeEquipment.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeEquipment.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(removeEquipment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // V1
            .addCase(getAllProjectList.pending, (state) => {
                state.projectsLoading = true;
            })
            .addCase(getAllProjectList.fulfilled, (state, action) => {
                state.projectsLoading = false;
                const { result } = action.payload;
                state.allProjects = result?.projects || [];
            })
            .addCase(getAllProjectList.rejected, (state, action) => {
                state.projectsLoading = false;
                state.allProjects = [];
                state.allProjects = action.error.message;
            })
            .addCase(getProjectById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProjectById.fulfilled, (state, action) => {
                state.loading = false;
                const { result } = action.payload;
                state.projectById = result?.projects || [];
            })
            .addCase(getProjectById.rejected, (state, action) => {
                state.loading = false;
                state.projectById = [];
                state.projectById = action.error.message;
            })
            .addCase(getCustomerId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCustomerId.fulfilled, (state, action) => {
                state.loading = false;
                const { result } = action.payload;
                state.customerId = result?.customers || [];
            })
            .addCase(getCustomerId.rejected, (state, action) => {
                state.loading = false;
                state.projectById = [];
                state.projectById = action.error.message;
            })
            .addCase(postProjectList.pending, (state) => {
                state.loading = true;
            })
            .addCase(postProjectList.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload;
            })
            .addCase(postProjectList.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })
            .addCase(getProjectEquipRelation.pending, (state) => {
                state.projectEquipLoading = true;
            })
            .addCase(getProjectEquipRelation.fulfilled, (state, action) => {
                state.projectEquipLoading = false;
                const { result } = action.payload;
                state.projectEquipRelation = result?.equipments || [];
            })
            .addCase(getProjectEquipRelation.rejected, (state, action) => {
                state.projectEquipLoading = false;
                state.projectEquipRelation = [];
                state.projectEquipRelation = action.error.message;
            })
            .addCase(getDensityByProjectName.pending, (state) => {
                state.viewDensityByProjectLoading = true;
            })
            .addCase(getDensityByProjectName.fulfilled, (state, action) => {
                state.viewDensityByProjectLoading = false;
                const { result } = action.payload;
                console.log("action.payload", action.payload);
                state.viewDensityByProject = result?.coreDensity || [];
            })
            .addCase(getDensityByProjectName.rejected, (state, action) => {
                state.viewDensityByProjectLoading = false;
                state.viewDensityByProject = [];
                state.viewDensityByProject = action.error.message;
            })
            .addCase(getAllProjectEquipmentList.pending, (state) => {
                state.projectEquipmentLoading = true;
            })
            .addCase(getAllProjectEquipmentList.fulfilled, (state, action) => {
                state.projectEquipmentLoading = false;
                const { result } = action.payload;
                state.allProjectEquipment = result?.equipments || [];
            })
            .addCase(getAllProjectEquipmentList.rejected, (state, action) => {
                state.projectEquipmentLoading = false;
                state.allProjectEquipment = [];
                state.allProjectEquipment = action.error.message;
            });
    },
});

export default projectSlice.reducer;
