import * as React from 'react';
import { Link } from "react-router-dom";
// MUI
import {
    Box, Typography, Dialog, DialogTitle, DialogContent
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

// ----------------------------------------------------------------------

export default function ProjectDialog({ isOpen, onClose, project }) {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Project
                </Box>
                <Link
                    to={`/projects/form?id=${project?.projectSK}`}
                    variant="body2"
                >
                    <EditIcon />
                </Link>
            </DialogTitle>
            <DialogContent>
                <Typography component="div" color="text.secondary">
                    <table>
                        <tbody>
                            <tr>
                                <td style={headerStyle}>Name:</td>
                                <td style={colStyle}>{project?.projectName}</td>
                            </tr>
                            <tr>
                                <td style={headerStyle}>Start Date:</td>
                                <td style={colStyle}>{project?.startDate ? new Date(project.startDate).toLocaleDateString() : '-'}</td>
                            </tr>
                            <tr>
                                <td style={headerStyle}>End Date:</td>
                                <td style={colStyle}>{project?.endDate ? new Date(project.endDate).toLocaleDateString() : '-'}</td>
                            </tr>
                            <tr>
                                <td style={headerStyle}>Equipment:</td>
                                <td style={colStyle}>
                                    {project?.equipment?.map((equipment) => equipment.name).join(', ')}
                                    {!project?.equipment?.length && '-'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

const headerStyle = {
    width: '1px',
    borderWidth: 0,
    padding: '0 10px 5px 0',
    fontSize: '16px',
}
const colStyle = {
    borderWidth: 0,
    padding: '0 10px 5px 0',
    fontSize: '16px',
}