import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    drumSizeListLoading: false,
    drumSizeRowLoading: false,
    allDrumSize: [],
    drumSizeById: [],
    formSuccess: [],
    error: ''
};

export const getAllDrumSize = createAsyncThunk(
    'drumSize/getAllDrumSize',
    async () => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetAllDrum`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getDrumSizeById = createAsyncThunk(
    'drumSize/getDrumSizeById',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetDrumById?drumSK=${loginIds.rowId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postDrumSize = createAsyncThunk(
    'drumSize/postDrumSize',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Device/AddDrum?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const drumSizeSlice = createSlice({
    name: 'drumSize',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllDrumSize.pending, (state) => {
                state.drumSizeListLoading = true;
            })
            .addCase(getAllDrumSize.fulfilled, (state, action) => {
                state.drumSizeListLoading = false;
                const { result } = action.payload;
                state.allDrumSize = result?.drums || [];
            })
            .addCase(getAllDrumSize.rejected, (state, action) => {
                state.drumSizeListLoading = false;
                state.allDrumSize = [];
                state.allDrumSize = action.error.message;
            })
            .addCase(getDrumSizeById.pending, (state) => {
                state.drumSizeRowLoading = true;
            })
            .addCase(getDrumSizeById.fulfilled, (state, action) => {
                state.drumSizeRowLoading = false;
                const { result } = action.payload;
                state.drumSizeById = result?.drum || [];
            })
            .addCase(getDrumSizeById.rejected, (state, action) => {
                state.drumSizeRowLoading = false;
                state.drumSizeById = [];
                state.drumSizeById = action.error.message;
            })
            .addCase(postDrumSize.fulfilled, (state, action) => {
                state.formSuccess = action.payload;
            })
            .addCase(postDrumSize.rejected, (state, action) => {
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })
    },
});

export default drumSizeSlice.reducer;