import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// MUI
import {
    Box, Card, CardContent, Button
} from "@mui/material";
// redux
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "src/features/userSlice";
// components
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import UserTable from "./components/UserTable";
import ChangePasswordDialog from "./components/ChangePasswordDialog";
import UserFormDialog from "./components/UserFormDialog";
// types
import { PermissionTypes } from "../../@core/data/permission";
// assets
import { iconSet } from "../../@core/data/icons";

// ----------------------------------------------------------------------

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "", label: "Users" },
];

// ----------------------------------------------------------------------

const UserPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const { roles } = useSelector((state) => state.login);
    const [changePassDialog, setChangePassDialog] = useState({ open: false, user: null });
    const [updateUserDialog, setUpdateUserDialog] = useState({ open: false, user: null });
    const { adminSelectedCompany } = useSelector((state) => state.company);
    const canCreateUser = !!roles.userCompanyRolePermissionInfo.find(
        (role) => role.permissionName === PermissionTypes.User && role.isWrite
    );
    const canEditRoles = !!roles.userCompanyRolePermissionInfo.find(
        (role) => role.permissionName === PermissionTypes.Roles && role.isWrite
    );

    const handleUpdatePassword = (user) => {
        setChangePassDialog({ open: true, user });
    }

    const handleUpdateUser = (user) => {
        setUpdateUserDialog({ open: true, user });
    }

    const handleAddUser = () => {
        setUpdateUserDialog({ open: true, user: null });
    }

    const onUserRolesClick = () => {
        navigate("/roles");
    }

    const onRolePermissionsClick = () => {
        navigate("/permissions");
    };

    const onUserFromClose = useCallback((didChange) => {
        setUpdateUserDialog({ ...updateUserDialog, open: false });

        if(didChange){
            if(isSuperAdmin && adminSelectedCompany.companyId) {
                dispatch(getUsers({ companyId: adminSelectedCompany.companyId }));
            } else {
                dispatch(getUsers());
            }
        }
    }, [dispatch, adminSelectedCompany]);

    return (
        <Box className="d-flex flex-column px-md-4">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Users" breadcrumbs={breadcrumbs} icon={iconSet.users} />
                </Box>
                <Box className="heading-right-btn">
                    {(isSuperAdmin || canCreateUser) && (
                        <Button variant="contained" onClick={handleAddUser}>
                            Add User
                        </Button>
                    )}
                </Box>
            </Box>

            <Box sx={{ mb: 2 }}>
                {(isSuperAdmin || canEditRoles) && (
                    <Button variant="contained" onClick={onUserRolesClick} sx={{ mr: 2 }}>
                        Roles
                    </Button>
                )}
                {(isSuperAdmin || canEditRoles) && (
                    <Button variant="contained" onClick={onRolePermissionsClick}>
                        Permissions
                    </Button>
                )}
            </Box>

            <Card>
                <CardContent>
                    <UserTable
                        onUpdatePassword={handleUpdatePassword}
                        onUpdateUser={handleUpdateUser}
                    />
                </CardContent>
            </Card>

            <ChangePasswordDialog
                open={changePassDialog.open}
                onClose={() => setChangePassDialog({ ...changePassDialog, open: false })}
                user={changePassDialog.user}
            />

            <UserFormDialog
                open={updateUserDialog.open}
                onClose={onUserFromClose}
                user={updateUserDialog.user}
            />
        </Box>
    );
};

export default UserPage;
