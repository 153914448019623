import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosApiInstance } from "src/@core/utils/axios";
// forms
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// MUI
import {
    Grid, 
    TextField, 
    Box, 
    Autocomplete,
    Typography,
} from "@mui/material";
// redux
import { getDataKeyById, createDataKey, updateDataKey, getUnassignedDataKeys } from "src/features/dataKeySlice";
// components
import { ToastSuccess, ToastError } from 'src/components/Toast';
import LoadingButton from 'src/components/LoadingButton';
import ConfirmDialog from "src/components/ConfirmDialog";

// ----------------------------------------------------------------------

const defaultFormValues = {
    companySK: '',
    equipmentSK: '',
    projectSK: '',
};

// ----------------------------------------------------------------------

export default function DataKeyForm({ dataKey, onSaved }) {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const { allCompanies } = useSelector((state) => state.company);
    const { dataKeyById } = useSelector((state) => state.dataKey);
    const { adminSelectedCompany } = useSelector((state) => state.company);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [removeConfirm, setRemoveConfirm] = useState({ open: false });

    useEffect(() => {
        if(!dataKey?.id) return;

        dispatch(getDataKeyById(dataKey.id));
    }, [dataKey])

    useEffect(() => {
        if(!dataKeyById?.companySK) return;
        if(dataKey?.id && dataKeyById?.id !== dataKey.id) return; // going to assume this is an old value

        setValue('companySK', dataKeyById.companySK);
        setValue('equipmentSK', dataKeyById.equipmentSK);
        setValue('projectSK', dataKeyById.projectSK);
    }, [dataKeyById]);

    useEffect(() => {
        if(!allCompanies?.length) return;

        const dropdownValues = allCompanies.map((company) => ({
            value: company.companySK,
            label: company.companyName,
        }));

      setCompanyOptions(dropdownValues);
    }, [allCompanies]);
    
    const formSchema = Yup.object().shape({
        companySK: Yup.string(),
        projectSK: isSuperAdmin ? Yup.string() : Yup.string().required('ProjectSK is required'),
        equipmentSK: Yup.string(),
    });

    const { 
        watch, 
        register, 
        handleSubmit, 
        reset, 
        control, 
        setValue, 
        getValues,
        formState: { errors } 
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: dataKey || defaultFormValues
    });

    const companySK = watch('companySK');
    const projectSK = watch('projectSK');

    useEffect(() => {
        if (!companySK){
            setProjectOptions([]);
            setEquipmentOptions([]);
            setValue('projectSK', '');
            setValue('equipmentSK', '');
            return;
        }

        updateFormOptions(companySK);
    }, [companySK]); // Use companySK directly in the dependency array

    const updateFormOptions = async (companySK) => {
        const projectResponse = await getProjects(companySK);
        const equipmentResponse = await getEquipment(companySK);

        const projects = projectResponse.data;
        const equipment = equipmentResponse.data;

        const projectDropdownValues = projects.map((project) => ({
            value: project.projectSK,
            label: project.projectName,
        }));

        setProjectOptions(projectDropdownValues);

        const equipmentDropdownValues = equipment.map((equipment) => ({
            value: equipment.equipmentSK,
            label: equipment.equipmentID,
        }));

        setEquipmentOptions(equipmentDropdownValues);
    }

    const getProjects = async (companySK) => {
        let url = `/api/v2/projects`;

        if (companySK) {
            url += `?companyId=${companySK}`;
        }

        const response = await axiosApiInstance.get(url);
        return response;
    }

    const getEquipment = async (companySK) => {
        let url = `/api/v2/equipment`;

        if (companySK) {
            url += `?companyId=${companySK}`;
        }

        const response = await axiosApiInstance.get(url);
        return response;
    }

    const onSubmit = (data) => {
        setSubmitting(true);

        if (dataKey?.id) {
            const updateData = {
                id: dataKey.id,
                data: data,
            };

            dispatch(updateDataKey(updateData)).then((res) => {
                setSubmitting(false);
                ToastSuccess("Data key saved");
                onSaved();
            });
        } else {
            const createData = {
                ...data,
                systemInfoId: systemInfoId
            };

            dispatch(createDataKey(createData)).then((res) => {
                setSubmitting(false);
                ToastSuccess("Data key saved");
                onSaved();
            });
        }
    }

    const onSubmitRemoveFromProject = (data) => {
        setSubmitting(true);

        if (!dataKey.id) {
            return;
        }

        const updateData = {
            id: dataKey.id,
            data: data,
        };

        dispatch(updateDataKey(updateData)).then((res) => {
            setSubmitting(false);
            ToastSuccess("Data key removed");
            refreshUnassignedDataKeys();
            onSaved();
        });
    }

    const refreshUnassignedDataKeys = () => {
        if (isSuperAdmin && adminSelectedCompany?.companyId) {
            const data = { unassigned: true, companyId: adminSelectedCompany.companyId };
            dispatch(getUnassignedDataKeys(data));
        } else {
            const data = { unassigned: true };
            dispatch(getUnassignedDataKeys(data));
        }
    }

    const removeProject = () => {
        setValue('projectSK', '');

        const data = getValues();
        onSubmitRemoveFromProject(data);
    }

    const confirmRemoveProject = () => {
        setRemoveConfirm({ open: true });
    }

    const closeRemoveConfirm = (confirm) => {
        if (confirm) {
            removeProject();
        }

        setRemoveConfirm({ open: false });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container spacing={2}>

                {isSuperAdmin && (
                    <>
                        <Grid item sm={12} md={6}>
                            <Controller
                                name="companySK"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        noOptionsText="Select Value"
                                        options={companyOptions}
                                        getOptionLabel={option => option.label}
                                        value={companyOptions.find(item => item.value === value) || null}
                                        onChange={(event, newValue) => {
                                            onChange(newValue ? newValue.value : '');
                                        }}
                                        renderInput={(params) =>
                                            <TextField label="Company" {...params} />
                                        }
                                    >
                                    </Autocomplete>
                                )}
                            />
                            {errors.companySk && (
                                <p className="text-error">Company is required</p>
                            )}
                        </Grid>
                        <Grid item sm={12} md={6} />
                    </>
                )}
                    
                <Grid item xs={12} md={6}>
                    <Controller
                        name="projectSK"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                noOptionsText="Select Value"
                                options={projectOptions}
                                getOptionLabel={option => option.label}
                                value={projectOptions.find(item => item.value === value) || null}
                                onChange={(event, newValue) => {
                                    onChange(newValue ? newValue.value : '');
                                }}
                                renderInput={(params) =>
                                    <TextField label="Project" {...params} />
                                }
                                disabled={!companySK}
                            >
                            </Autocomplete>
                        )}
                    />
                    {errors.projectSK && (
                        <p className="text-error">Project is required</p>
                    )}
                    
                    <Box>
                        <Typography
                            variant="caption"
                            sx={{ color: projectSK ? 'primary.main' : 'grey.500', cursor: projectSK ? 'pointer' : 'default' }}
                            onClick={projectSK ? confirmRemoveProject : null}
                        >
                            Remove
                        </Typography>
                    </Box>
                </Grid>
                    
                {isSuperAdmin && (
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="equipmentSK"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    noOptionsText="Select Value"
                                    options={equipmentOptions}
                                    getOptionLabel={option => option.label}
                                    value={equipmentOptions.find(item => item.value === value) || null}
                                    onChange={(event, newValue) => {
                                        onChange(newValue ? newValue.value : '');
                                    }}
                                    renderInput={(params) =>
                                        <TextField label="Equipment" {...params} />
                                    }
                                    disabled={!companySK}
                                >
                                </Autocomplete>
                            )}
                        />
                        {errors.equipmentSK && (
                            <p className="text-error">Equipment is required</p>
                        )}
                    </Grid>
                )}
                    

                <Grid item sm={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={submitting}
                            sx={{ width: '110px' }}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>

            <ConfirmDialog
                open={removeConfirm.open}
                title="Remove Data Key"
                message="Are you sure you want to remove this data key from all projects?"
                onClose={closeRemoveConfirm}
                maxWidth="xs"
            />
        </form>
    );
};

