import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// style
import "./App.css";
import "./assets/scss/layout.scss";
// assets
import logoRTD from "src/assets/image/rtd-logo-1080.png";
// MUI
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { 
    MenuItem, 
    FormControl,
    Button,
} from "@mui/material";
// helpers
import { userName } from "./@core/utils/helper";
// componenets
import Navigation from "./components/navigation/navigation.component";
import ActionMenu from "./components/actionmenu";
// redux
import { 
    getCompanies, 
    setAdminSelectedCompany,
} from "./features/companySlice";
import { 
    DEFAULT_LOGIN_IDS,
    setLoginIds,
    setLoggedIn,
} from "src/features/loginSlice";
// routes
import RTDRoutes from "./routes";
// util
import { removeLoginStorage } from "src/@core/utils/loginHelper";

// ----------------------------------------------------------------------

const drawerWidth = 330;

const options = [
    'Profile',
    'Password',
];
const secondOptions = [
    'Logout',
];

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    //width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.down('sm')]: {
        width: 0,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

function App() {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
    const isLgOrLarger = useMediaQuery(theme.breakpoints.up('lg'));
    const [open, setOpen] = useState(isLgOrLarger);
    const [companyList, setCompanyList] = React.useState([]);
    const { adminSelectedCompany } = useSelector((state) => state.company);
    const isSuperAdmin = loginRes?.superAdmin;

    useEffect(() => {
        setOpen(isLgOrLarger);
    }, [isLgOrLarger]);

    useEffect(() => {
        const loginResupdate = {
            companyId: 0,
            userId: loginRes?.userId,
        };
        if (isSuperAdmin) {
            dispatch(getCompanies(loginResupdate)).then((company) => {
                if (company.payload) {
                    setCompanyList(company.payload);
                }
            });
        }
    }, []);

    const logout = () => {
        // navigate("/");
        removeLoginStorage();
        dispatch(setLoginIds(DEFAULT_LOGIN_IDS));
        dispatch(setLoggedIn(false));
        // Auth guard should now redirect to the login page
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleNavClick = () => {
        if (!isLgOrLarger) {
            setOpen(false);
        }
    }

    const onMenuItemClick = (e) => {
        if (e === 'Profile') {
            navigate('/account?tab=profile');
        } else if (e === 'Password') {
            navigate('/account?tab=password');
        } else if (e === 'Logout') {
            logout();
        }
    }

    const handleCompanyChange = (event) => {
        const value = event.target.value;

        if (value === 0) {
            dispatch(setAdminSelectedCompany({ companyName: '', companyId: value }));
            loginRes.companyName = '';
            loginRes.companyId = value;
        } else {
            const company = companyList.find((company) => company.companySK === value);

            if (company) {
                loginRes.companyName = company.companyName;
                loginRes.companyId = value;
                dispatch(setAdminSelectedCompany({ companyName: company.companyName, companyId: value }));
            }
        }

        localStorage.setItem("loginRes", JSON.stringify(loginRes));
    };

    return (
        <div className="customer-page">
            <Box sx={{ display: "flex" }}>
                <AppBar position="fixed" open={open} className="header-container">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="d-flex w-100">
                            <div className="header-new-label flex-grow-1">
                                <Typography variant="h6" noWrap component="div">
                                    {process.env.REACT_APP_APPNAME}
                                </Typography>
                            </div>
                            <div className="p-2">
                                <div className="nav-link nav-profile d-flex align-items-center pe-0">
                                    {!isMdOrSmaller && (
                                        <span className="py-2 link-body-emphasis text-decoration-none home-header-txt">
                                            {userName()}
                                        </span>
                                    )}

                                    <ActionMenu
                                        options={options}
                                        secondOptions={secondOptions}
                                        handleMenuClick={(e) => onMenuItemClick(e)}
                                    />

                                    {isSuperAdmin && (
                                        <section>
                                            <FormControl sx={{ m: 1, minWidth: '120px' }} size="small">
                                                <Select
                                                    size="small"
                                                    value={adminSelectedCompany.companyId}
                                                    onChange={handleCompanyChange}
                                                    input={<OutlinedInput />}
                                                >
                                                    <MenuItem value={0}>All</MenuItem>
                                                    {companyList.map((company) => (
                                                        <MenuItem value={company.companySK} key={company.companySK}>
                                                            {company.companyName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </section>
                                    )}
                                    {!isMdOrSmaller && (
                                        <Button
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            startIcon={<LogoutOutlinedIcon sx={{ fontSize: 20, color: "#ffffff" }} />}
                                            onClick={logout}
                                            sx={{ py: 1, ml: 1 }}
                                        >
                                            Logout
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <img src={logoRTD} alt="logo bg" className="logo" style={{ width: '200px' }} />
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <Navigation onItemClick={handleNavClick} />
                </Drawer>
                <Box className="container-fluid" component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <RTDRoutes />
                </Box>
            </Box>
        </div>
    );
}

export default App;
