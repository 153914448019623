import { useState } from "react";
import { useDispatch } from "react-redux";
// forms
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// MUI
import {
    Grid, TextField, Box, 
} from "@mui/material";
// redux
import { createIpc, updateIpc } from "src/features/ipcSlice";
// components
import { ToastSuccess, ToastError } from 'src/components/Toast';
import LoadingButton from 'src/components/LoadingButton';

// ----------------------------------------------------------------------

const defaultFormValues = {
    make: '',
    model: '',
    serialNumber: '',
};

// ----------------------------------------------------------------------

export default function IpcForm({ ipc, systemInfoId, onSaved }) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const formSchema = Yup.object().shape({
        make: Yup.string().required("Make is required"),
        model: Yup.string().required("Model is required"),
        serialNumber: Yup.string().required("Serial Number is required"),
    });

    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: ipc || defaultFormValues
    });

    const onSubmit = (data) => {
        setSubmitting(true);

        if (ipc?.id) {
            const updateData = {
                id: ipc.id,
                data: {
                    ...data,
                    systemInfoId: systemInfoId
                }
            };

            dispatch(updateIpc(updateData)).then((res) => {
                setSubmitting(false);
                ToastSuccess("IPC Saved");
                onSaved();
            });
        } else {
            const createData = {
                ...data,
                systemInfoId: systemInfoId
            };

            dispatch(createIpc(createData)).then((res) => {
                setSubmitting(false);
                ToastSuccess("IPC Saved");
                onSaved();
            });
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                    <Controller
                        name="make"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Make" />
                        )}
                    />
                </Grid>
                <Grid item sm={12} md={6}>
                    <Controller
                        name="model"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Model" />
                        )}
                    />
                </Grid>
                <Grid item sm={12} md={6}>
                    <Controller
                        name="serialNumber"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Serial Number" />
                        )}
                    />
                </Grid>

                <Grid item sm={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={submitting}
                            sx={{ width: '110px' }}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

