import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    equipmentLoading: false,
    allEquipment: [],
    equipmentById: [],
    availableEquipment: [],
    formSuccess: {},
    error: '',
    availableLoading: false,
    allAvailableEquipment: [],
    allEquipmentByCompanyLoading: false,
    allEquipmentByCompany: [],
};

export const getAllEquipmentList = createAsyncThunk(
    'equipment/getAllEquipmentList',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Equipment/GetAllEquipment?companyId=${loginIds.companyId}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getEquipmentByCompany = createAsyncThunk(
    "iot/getEquipmentByCompany",
    async (loginIds) => {
        try {

            const { data } = await axiosApiInstance.get(
                `/Equipment/GetEquipmentByCompany?companyId=${loginIds.companyId}&userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getAllAvailableEquipmentList = createAsyncThunk(
    'equipment/getAllAvailableEquipmentList',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Equipment/GetAllAvailableEquipment`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getAllEquipmentTablet = createAsyncThunk(
    'equipment/getAllEquipmentTablet',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Equipment/GetAllEquipmentTablet?companyId=${loginIds.companyId}&equipmentId=${loginIds.rowId}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getEquipmentById = createAsyncThunk(
    'equipment/getEquipmentById',
    async (ids) => {
        try {
            const { data } = await axiosApiInstance.get(`/Equipment/GetEquipment?equipmentId=${ids.rowId}&userId=${ids.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postEquipmentList = createAsyncThunk(
    'company/postEquipmentList',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Equipment/Save?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postAvailableEquipmentList = createAsyncThunk(
    'equipment/postAvailableEquipmentList',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Equipment/SaveAvailableEquipment?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postEquipmentConfig = createAsyncThunk(
    'company/postEquipmentConfig',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Equipment/SaveEquipmentTablet?equipmentId=${data.equipmentId}&tabletSK=${data.tabletSK}&userId=${loginId.userId}`);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postEquipmentCompanyConfig = createAsyncThunk(
    'company/postEquipmentCompanyConfig',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Equipment/SaveCompanyEquipment?userId=${loginId.userId}`,
                data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

// API V2

export const getAllEquipment = createAsyncThunk(
    'equipment/getAllEquipment',
    async (data = { companyId: null }) => {
        try {
            let url = `/api/v2/equipment?`;

            if (data.companyId) {
                url += `companyId=${data.companyId}&`;
            }

            url = url.slice(0, -1); // Remove the trailing '&' or '?'

            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
            throw error;
        }
    }
);

export const getAvailableEquipment = createAsyncThunk(
    'equipment/getAvailableEquipment',
    async (data = { companyId: null }) => {
        try {
            let url = `/api/v2/equipment/available?`;

            if (data.companyId) {
                url += `companyId=${data.companyId}&`;
            }

            url = url.slice(0, -1); // Remove the trailing '&' or '?'

            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const createEquipment = createAsyncThunk(
    'equipment/createEquipment',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/equipment`, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getEquipmentByIdV2 = createAsyncThunk(
    'equipment/getEquipmentByIdV2',
    async (id) => {
        try {
            const url = `/api/v2/equipment/${id}`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const clearEquipmentById = createAsyncThunk(
    'equipment/clearEquipmentById',
    async () => {
        return null;
    }
);

export const updateEquipment = createAsyncThunk(
    'equipment/updateEquipment',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/equipment/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const deleteEquipment = createAsyncThunk(
    'equipment/deleteEquipment',
    async (id) => {
        try {
            const url = `/api/v2/equipment/${id}`;
            const response = await axiosApiInstance.delete(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const equipmentSlice = createSlice({
    name: 'equipment',
    initialState,
    extraReducers: (builder) => {
        builder
            // API V2
            .addCase(getAllEquipment.pending, (state) => {
                state.loading = true;
                state.equipmentLoading = true;
            })
            .addCase(getAllEquipment.fulfilled, (state, action) => {
                state.loading = false;
                state.equipmentLoading = false;
                state.allEquipment = action.payload.data;
            })
            .addCase(getAllEquipment.rejected, (state, action) => {
                state.loading = false;
                state.equipmentLoading = false;
                state.allEquipment = [];
                state.error = action.error.message;
            })

            .addCase(getAvailableEquipment.pending, (state) => {
                state.loading = true;
                state.equipmentLoading = true;
            })
            .addCase(getAvailableEquipment.fulfilled, (state, action) => {
                state.loading = false;
                state.equipmentLoading = false;
                state.availableEquipment = action.payload.data;
            })
            .addCase(getAvailableEquipment.rejected, (state, action) => {
                state.loading = false;
                state.equipmentLoading = false;
                state.availableEquipment = [];
                state.error = action.error.message;
            })

            .addCase(createEquipment.pending, (state) => {
                state.loading = true;
            })
            .addCase(createEquipment.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload.data;
            })
            .addCase(createEquipment.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(getEquipmentByIdV2.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEquipmentByIdV2.fulfilled, (state, action) => {
                state.loading = false;
                state.equipmentById = action.payload.data;
            })
            .addCase(getEquipmentByIdV2.rejected, (state, action) => {
                state.loading = false;
                state.equipmentById = {};
                state.error = action.error.message;
            })

            .addCase(clearEquipmentById.fulfilled, (state) => {
                state.equipmentById = {};
            })

            .addCase(updateEquipment.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateEquipment.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload.data;
            })
            .addCase(updateEquipment.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(deleteEquipment.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteEquipment.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteEquipment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


            // API V1
            .addCase(getAllEquipmentList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllEquipmentList.fulfilled, (state, action) => {
                state.loading = false;
                const { result } = action.payload;
                state.allEquipment = result?.equipment || [];
            })
            .addCase(getAllEquipmentList.rejected, (state, action) => {
                state.loading = false;
                state.allEquipment = [];
                state.allEquipment = action.error.message;
            })
            .addCase(getAllAvailableEquipmentList.pending, (state) => {
                state.availableLoading = true;
            })
            .addCase(getAllAvailableEquipmentList.fulfilled, (state, action) => {
                state.availableLoading = false;
                const { result } = action.payload;
                state.allAvailableEquipment = result?.equipment || [];
            })
            .addCase(getAllAvailableEquipmentList.rejected, (state, action) => {
                state.availableLoading = false;
                state.allAvailableEquipment = [];
                state.allAvailableEquipment = action.error.message;
            })
            .addCase(getAllEquipmentTablet.pending, (state) => {
                state.equipmentLoading = true;
            })
            .addCase(getAllEquipmentTablet.fulfilled, (state, action) => {
                state.equipmentLoading = false;
                const { result } = action.payload;
                state.allEquipmentTablet = result?.tabletData || [];
            })
            .addCase(getAllEquipmentTablet.rejected, (state, action) => {
                state.equipmentLoading = false;
                state.allEquipmentTablet = [];
                state.allEquipmentTablet = action.error.message;
            })
            .addCase(getEquipmentById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEquipmentById.fulfilled, (state, action) => {
                state.loading = false;
                const { result } = action.payload;
                state.equipmentById = result?.equipment || [];
            })
            .addCase(getEquipmentById.rejected, (state, action) => {
                state.loading = false;
                state.equipmentById = [];
                state.equipmentById = action.error.message;
            })
            .addCase(postEquipmentList.pending, (state) => {
                state.loading = true;
            })
            .addCase(postEquipmentList.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload;
            })
            .addCase(postEquipmentList.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.formSuccess = action.error.message;
            })
            .addCase(postAvailableEquipmentList.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload;
            })
            .addCase(postAvailableEquipmentList.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.formSuccess = action.error.message;
            })
            .addCase(getEquipmentByCompany.pending, (state) => {
                state.allEquipmentByCompanyLoading = true;
            })
            .addCase(getEquipmentByCompany.fulfilled, (state, action) => {
                state.allEquipmentByCompanyLoading = false;
                const { result } = action.payload;
                state.allEquipmentByCompany = result?.equipment || [];
            })
            .addCase(getEquipmentByCompany.rejected, (state, action) => {
                state.allEquipmentByCompanyLoading = false;
                state.allEquipmentByCompany = [];
                state.allEquipmentByCompany = action.error.message;
            });
    },
});

export default equipmentSlice.reducer;
