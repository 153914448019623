import React from "react";
import { useState, useEffect } from "react";
import { axiosApiInstance } from "src/@core/utils/axios";
// redux
import { useDispatch } from "react-redux";
import { getCompanyById } from "src/features/companySlice";
// MUI
import {
    Box,  Card, CardContent, CardHeader,
    Grid, Button, Divider
} from "@mui/material";
import { iconSet } from "src/@core/data/icons";
// components
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import { ToastSuccess, ToastError } from "src/components/Toast";
import CustomerForm from "./components/CustomerForm";
import ConfirmDialog from "src/components/ConfirmDialog";

// ----------------------------------------------------------------------

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "/customers", label: "Customers" }
];

// ----------------------------------------------------------------------

export default function CustomerPage() {
    const dispatch = useDispatch();
    const [customers, setCustomers] = useState([]);
    const [companyId, setCompanyId] = useState(0);
    const [deleteCustomerConfirm, setDeleteCustomerConfirm] = useState({ open: false, customerId: null });
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const myCompanyId = loginRes.companyId;

    useEffect(() => {
        if (myCompanyId) {
            setCompanyId(myCompanyId);

            dispatch(getCompanyById(myCompanyId)).then((res) => {
                setCustomers(res.payload.customers);
            });
        }
    }, [dispatch, myCompanyId]);

    const deleteCustomer = async (customerId) => {
        const url = `/api/v2/customers/${customerId}`;

        try {
            await axiosApiInstance.delete(url);
            setDeleteCustomerConfirm({ open: false, customer: null });
            setCustomers(customers.filter((item) => item.customerSK !== customerId));
            ToastSuccess('Customer deleted!');
        } catch (error) {
            ToastError('An error occurred');
            console.error(error);
        }
    };

    const closeDeleteCustomerConfirm = (confirm) => {
        if (confirm) {
            console.log('deleteCustomerCOnfirm: ', deleteCustomerConfirm);
            if (deleteCustomerConfirm.customerId < 0) {
                // remove the dummy customer object
                setCustomers(customers.filter((item) => item.customerSK !== deleteCustomerConfirm.customerId));
                setDeleteCustomerConfirm({ open: false, customerId: null });
            } else {
                deleteCustomer(deleteCustomerConfirm.customerId);
            }
        } else {
            setDeleteCustomerConfirm({ open: false, customerId: null });
        }
    }

    const onDeleteClick = (customerId) => {
        setDeleteCustomerConfirm({ open: true, customerId: customerId });
    }

    const addCustomer = () => {
        // Add a dummy id to track the new customer record with
        let newCustomerId = Math.min(...customers.map(customer => customer.customerSK)) - 1;
        newCustomerId = Math.min(newCustomerId, -1);

        setCustomers([{ customerSK: newCustomerId }, ...customers])
    }

    const onCustomerSaved = (customerData, oldCustomerId=null) => {
        const existingCustomer = customers.find(customer => customer.customerSK === customerData.customerSK);
        const oldCustomer = customers.find(customer => customer.customerSK === oldCustomerId);

        if (existingCustomer) {
            setCustomers(customers.map(customer => customer.customerSK === customerData.customerSK ? customerData : customer));
        } else if(oldCustomer) {
            setCustomers(customers.map(customer => customer.customerSK === oldCustomerId ? customerData : customer));
        } else {
            setCustomers([...customers, customerData]);
        }
    }

    return (
        <Box className="d-flex flex-column px-md-4 form-layout">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Customers" breadcrumbs={breadcrumbs} icon={iconSet.company} />
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12} xl={7}>
                    <Card>
                        <CardHeader
                            title="Customers"
                            action={
                                <Button variant="contained" color="primary" onClick={addCustomer} disabled={!myCompanyId}>
                                    Add Customer
                                </Button>
                            }
                        />
                        <CardContent>
                            {customers.map((item, index) => (
                                <Box key={item.customerSK}>
                                    <CustomerForm companyId={companyId} customerId={item.customerSK} onDelete={onDeleteClick} onSaved={onCustomerSaved} />
                                    {index < customers.length - 1 && <Divider sx={{ my: 2 }} />}
                                </Box>
                            ))}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <ConfirmDialog
                open={deleteCustomerConfirm.open}
                title="Delete Customer"
                message="Are you sure you want to delete this customer? This cannot be undone. This will not delete the company"
                onClose={closeDeleteCustomerConfirm}
                maxWidth="xs"
            />
        </Box>
    );
};
