import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// redux
import { getAllDataKeys } from "src/features/dataKeySlice";
// MUI
import {
    Box, 
    Card, 
    CardContent,
} from "@mui/material";
// components
import ActionMenu from "../../components/actionmenu";
import Table from "../../components/table/Table";
import { iconSet } from "../../@core/data/icons";
import { CircleLoading } from "../../components/loadingCircle";
import ConfirmDialog from "src/components/ConfirmDialog";
import DataKeyFormDialog from "./components/DataKeyFormDialog";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
// util
import { formatDate, formatUtcToLocalTz } from "src/@core/utils/dateTimeFormatter";

// ----------------------------------------------------------------------

//let adminOptions = ["Edit", "Delete"]; // removing delete option until we have soft deletes
let adminOptions = ["Edit"];

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "", label: "Data Keys" },
];

// ----------------------------------------------------------------------

export default function DataKeyPage() {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const { loading, dataKeys } = useSelector((state) => state.dataKey);
    const [formattedDataKeys, setFormattedDataKeys] = useState([]);
    const [deleteConfirm, setDeleteConfirm] = useState({ open: false, id: null });
    const [dataKeyDialog, setDataKeyDialog] = useState({ open: false, dataKey: null });
    let hideColumn = ['id', 'projectName', 'equipmentID'];

    const COLUMNS = [
        {
            Header: "Id",
            accessor: "id",
            show: false,
        },
        {
            Header: "Key",
            accessor: "keyValue",
        },
        {
            Header: "SystemId",
            accessor: "systemId",
            Cell: ({ row }) => {
                return (row.values.systemId ? row.values.systemId : "-");
            }
        },
        {
            Header: "Project",
            accessor: "projectName",
            Cell: ({ row }) => {
                return (isSuperAdmin && row.values.projectSK) ? (
                    <div>
                        <Link
                            style={{ textAlign: "left" }}
                            to={`/projects`}
                        >
                            {row.values.projectName !== null ? row.values.projectName : "-"}
                        </Link>
                    </div>
                ) : (
                    <div>
                        {row.values.projectName !== null ? row.values.projectName : "-"}
                    </div>
                )
            }
        },
        {
            Header: "ProjectSK",
            accessor: "projectSK",
            Cell: ({ row }) => {
                return (row.values.projectSK) ? (
                    <div> {row.values.projectSK} </div>
                ) : (
                    <div> - </div>
                )
            }
        },
        {
            Header: "Equipment",
            accessor: "equipmentID",
            Cell: ({ row }) => {
                return (isSuperAdmin && row.values.equipmentSK) ? (
                    <div>
                        <Link
                            style={{ textAlign: "left" }}
                            to={`/equipment/form?id=${row.values.equipmentSK}`}
                        >
                            {row.values.equipmentID !== null ? row.values.equipmentID : "-"}
                        </Link>
                    </div>
                ) : (
                    <div>
                        {row.values.equipmentID !== null ? row.values.equipmentID : "-"}
                    </div>
                )
            }
        },
        {
            Header: "EquipmentSk",
            accessor: "equipmentSK",
            Cell: ({ row }) => {
                return (isSuperAdmin && row.values.equipmentSK) ? (
                    <div>
                        <Link
                            style={{ textAlign: "left" }}
                            to={`/equipment/form?id=${row.values.equipmentSK}`}
                        >
                            {row.values.equipmentSK !== null ? row.values.equipmentSK : "-"}
                        </Link>
                    </div>
                ) : (
                    <div>
                        {row.values.equipmentSK !== null ? row.values.equipmentSK : "-"}
                    </div>
                )
            }
        },
        {
            Header: "Time",
            accessor: "formatted_timestamp_local",
        },
        {
            Header: "Time (utc)",
            accessor: "formatted_timestamp",
        },
        {
            Header: "Action",
            accessor: 'action',
            Cell: ({ row }) => (
                <Box>
                    {isSuperAdmin && (
                        <ActionMenu
                            key="action-menu"
                            options={adminOptions}
                            handleMenuClick={(option) =>
                                handleItemMenuClick(row.values, option)
                            }
                        />
                    )}
                </Box>
            ),
        },
    ];

    const onDeleteClick = (id) => {
        setDeleteConfirm({ open: true, id: id });
    }

    const closeDeleteConfirm = (confirm) => {
        if (confirm) {
            deleteEquipment(deleteConfirm.id);
        }

        setDeleteConfirm({ open: false, id: null });
    }

    useEffect(() => {
        dispatch(getAllDataKeys());
    }, [dispatch]);

    useEffect(() => {
        if (dataKeys) {
            const formatted = dataKeys.map(dataKey => ({
                ...dataKey,
                formatted_timestamp: formatDate(dataKey.timestamp, "yyyy-MM-dd'T'HH:mm:ss", 'MM/dd/yyyy hh:mm:ss'),
                formatted_timestamp_local: formatUtcToLocalTz(dataKey.timestamp, 'MM/dd/yyyy hh:mm:ss')
            }));
            setFormattedDataKeys(formatted);
        }
    }, [dataKeys]);

    const handleItemMenuClick = (values, option) => {
        if (option === "Edit") {
            setDataKeyDialog({ open: true, dataKey: values });
        } else if (option === "Delete") {
            onDeleteClick(values.id);
        }
    };

    const closeDataKeyDialog = () => {
        setDataKeyDialog({ open: false, dataKey: null });
    }

    return (
        <Box className="d-flex flex-column px-md-4 form-layout customer-form">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Data Keys" breadcrumbs={breadcrumbs} icon={iconSet.dataKey} />
                </Box>
            </Box>
            <Card>
                <CardContent>
                    {loading && (<CircleLoading />)}

                    {!loading && (
                        <Table
                            COLUMNS={COLUMNS}
                            parseData={formattedDataKeys}
                            hideColumn={hideColumn}
                            heading={"Data Keys"}
                        />
                    )}
                </CardContent>
            </Card>

            <ConfirmDialog
                open={deleteConfirm.open}
                title="Delete Data Key"
                message="Are you sure you want to delete this Data Key? This cannot be undone."
                onClose={closeDeleteConfirm}
                maxWidth="xs"
            />

            <DataKeyFormDialog
                open={dataKeyDialog.open}
                onClose={closeDataKeyDialog}
                dataKey={dataKeyDialog.dataKey}
            />
        </Box>
    );
};

