import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    deviceTypeListLoading: false,
    deviceTypeRowLoading: false,
    allDeviceType: [],
    deviceTypeById: [],
    formSuccess: [],
    error: ''
};

export const getAllDeviceType = createAsyncThunk(
    'deviceType/getAllDeviceType',
    async () => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetAllType?deviceType=A`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getDeviceTypeById = createAsyncThunk(
    'deviceType/getDeviceTypeById',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetDeviceTypeById?typeSK=${loginIds.rowId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postType = createAsyncThunk(
    'deviceType/postType',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Device/AddType?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const deviceTypeSlice = createSlice({
    name: 'deviceType',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeviceType.pending, (state) => {
                state.deviceTypeListLoading = true;
            })
            .addCase(getAllDeviceType.fulfilled, (state, action) => {
                state.deviceTypeListLoading = false;
                const { result } = action.payload;
                state.allDeviceType = result?.types || [];
            })
            .addCase(getAllDeviceType.rejected, (state, action) => {
                state.deviceTypeListLoading = false;
                state.allDeviceType = [];
                state.allDeviceType = action.error.message;
            })
            .addCase(getDeviceTypeById.pending, (state) => {
                state.deviceTypeRowLoading = true;
            })
            .addCase(getDeviceTypeById.fulfilled, (state, action) => {
                state.deviceTypeRowLoading = false;
                const { result } = action.payload;
                state.deviceTypeById = result?.type || [];
            })
            .addCase(getDeviceTypeById.rejected, (state, action) => {
                state.deviceTypeRowLoading = false;
                state.deviceTypeById = [];
                state.deviceTypeById = action.error.message;
            })
            .addCase(postType.fulfilled, (state, action) => {
                state.formSuccess = action.payload;
            })
            .addCase(postType.rejected, (state, action) => {
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })
    },
});

export default deviceTypeSlice.reducer;