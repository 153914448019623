import React from "react";
import { Navigate } from "react-router-dom";
// redux
import {
    selectLoggedIn,
} from "src/features/loginSlice";
import { useSelector } from "react-redux";

const RequireAuth = ({ children }) => {
  const loggedIn = useSelector(selectLoggedIn);

  if (!loggedIn) {
    return <Navigate to="/" replace />; // Redirect to the login page
  }

  return children;
};

export default RequireAuth;
